<wui-app>
    <wui-drawer *ngIf="isLoggedIn">
        <div class="wui-drawer-inner">
            <div class="wui-drawer-header">
                <div class="wui-drawer-header-avatar mx-4 mb-3">{{initial}}</div>
                <div class="wui-drawer-header-title font-18 font-weight-500 px-4">{{penggunaAktif?.nmKaryawan}}</div>
                <div class="wui-drawer-header-subtitle text-wrap">{{penggunaAktif?.akses != 'ADMINISTRATOR' ? penggunaAktif?.akses + ' - ' + penggunaAktif?.nmUnit : penggunaAktif?.akses | titlecase}}</div>
            </div>

            <div class="pl-4 pr-2 mb-4"></div>

            <wui-drawer-item icon="home" routerLink="/home" routerLinkActive="active">Dashboard</wui-drawer-item>
            <wui-drawer-item icon="text-box" routerLink="/laporan" routerLinkActive="active">Laporan</wui-drawer-item>
            <wui-drawer-item icon="file-table-box" routerLink="/laporanTerkait" routerLinkActive="active">Laporan Terkait</wui-drawer-item>

            @if (penggunaAktif.akses == 'ADMINISTRATOR') {
                <div class="wui-drawer-divider">
                    <div class="wui-drawer-divider-label">MASTER DATA</div>
                </div>
                <wui-drawer-item icon="office-building" routerLink="/unit" routerLinkActive="active">Unit</wui-drawer-item>
                <wui-drawer-item icon="account-tie" routerLink="/jabatan" routerLinkActive="active">Jabatan</wui-drawer-item>
            }

            <!-- <div class="wui-drawer-divider">
                <div class="wui-drawer-divider-label">INFO KERJASAMA</div>
            </div>
            <wui-drawer-item icon="handshake" routerLink="/kerjasama" routerLinkActive="active">Kerjasama</wui-drawer-item> -->


            <div class="wui-drawer-divider">
                <div class="wui-drawer-divider-label">PENGATURAN</div>
            </div>
            <wui-drawer-item icon="lock-reset" routerLink="/profil" routerLinkActive="active">Ubah Password</wui-drawer-item>
            @if (penggunaAktif.akses == 'ADMINISTRATOR') {
                <wui-drawer-item icon="account-circle" routerLink="/pengguna" routerLinkActive="active">Pengguna</wui-drawer-item>
            }
            <wui-drawer-item icon="exit-to-app" (click)="signOut()">Logout</wui-drawer-item>
        </div>
    </wui-drawer>

    <router-outlet *ngIf="isLoggedIn != null"></router-outlet>

    <wui-page *ngIf="isLoggedIn == null">
        <div class="wui-page-inner d-flex align-items-center justify-content-center">
            <wui-loading mode="circular"></wui-loading>
        </div>
    </wui-page>
</wui-app>