<wui-page>
    <wui-app-bar>
        @if (searchMode == true) {
            <div class="wui-app-bar-leading">
                <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="toggleSearch()">
                    <wui-icon icon="arrow-left"></wui-icon>
                </button>
            </div>
            <div class="wui-app-bar-title">
                <input type="text" (keyup)="search.next($event)" placeholder="Kata kunci..." [(ngModel)]="q" #searchInput>
            </div>
        }

        @if (!searchMode) {
            <div class="wui-app-bar-leading">
                <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" wuiToggleDrawer>
                    <wui-icon icon="menu"></wui-icon>
                </button>
            </div>
            <div class="wui-app-bar-title"></div>
            <div class="wui-app-bar-trailing">
                <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="toggleSearch()">
                    <wui-icon icon="magnify"></wui-icon>
                </button>
                <button class="wui-button wui-button-large wui-button-icon wui-button-smooth"  (click)="refresh()">
                    <wui-icon icon="refresh"></wui-icon>
                </button>
            </div>
        }
    </wui-app-bar>

    <div class="wui-page-inner">
        <div class="wui-page-container">
            <div class="mb-5 d-flex align-items-center">
                <div class="flex-grow-1 font-28 font-weight-500">Data Jabatan</div>
                <div>
                    <button class="wui-button wui-button-primary" routerLink="baru" queryParamsHandling="merge">
                        <wui-icon icon="plus"></wui-icon> Baru
                    </button>
                </div>
            </div>

            <div class="table-responsive-sm">
                <table class="table align-middle table-hover">
                    <thead>
                        <tr class="align-middle">
                            <th width="35%" scope="col" colspan="2">Nama Jabatan</th>
                            <th width="30%" scope="col">Unit</th>
                            <th width="30%" scope="col">Akses</th>
                            <th width="5%" scope="col"></th>
                        </tr>
                    </thead>

                    @for (jabatan of dataJabatan; track $index;) {
                        <tbody>
                            <tr (click)="selectedIndex = $index">
                                <th width="5%" scope="row">
                                    <wui-icon icon="account-tie"></wui-icon>
                                </th>
                                <td width="30%">{{jabatan.nmJabatan}}</td>
                                <td width="30%">{{jabatan.nmUnit}}</td>
                                <td width="30%">{{jabatan.akses}}</td>
                                <td>
                                    <button class="wui-button wui-button-icon wui-button-smooth" [wuiMenu]="rowMenu">
                                        <wui-icon icon="dots-vertical"></wui-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    }
                </table>

                @if (isLoading === true) {
                    <div class="p-5 text-center mx-auto">
                        <wui-loading mode="circular"></wui-loading>
                    </div>
                }
            </div>

            @if (!isLoading) {
                <wui-list-tile class="wui-list-tile-64 ps-2 wui-list-tile-no-hover" *ngIf="dataJabatan.length == 0">
                    <div class="wui-list-tile-content fst-italic">Tidak ada data</div>
                </wui-list-tile>

                <wui-list-tile class="wui-list-tile-no-hover wui-list-tile-64" *ngIf="dataJabatan.length > 0">
                    <div class="wui-list-tile-content">
                        <div class="d-inline-flex align-items-center">
                            <button class="wui-button wui-button-icon wui-button-smooth" (click)="first()" [disabled]="count == 0 || page == 1">
                                <wui-icon icon="page-first"></wui-icon>
                            </button>
                            <button class="wui-button wui-button-icon wui-button-smooth" (click)="prev()" [disabled]="count == 0 || page == 1">
                                <wui-icon icon="chevron-left"></wui-icon>
                            </button>
                            <div class="flex-grow-1 px-4">
                                <span *ngIf="isLoading">Memuat...</span>
                                <span *ngIf="!isLoading">{{offset + 1}} - {{count < (offset + limit) ? count : (offset + limit)}} dari {{count | number: '1.0-2' }} baris</span>
                            </div>
                            <button class="wui-button wui-button-icon wui-button-smooth" (click)="next()" [disabled]="count == 0 || page == pageCount">
                                <wui-icon icon="chevron-right"></wui-icon>
                            </button>
                            <button class="wui-button wui-button-icon wui-button-smooth"  (click)="last()" [disabled]="count == 0 || page == pageCount">
                                <wui-icon icon="page-last"></wui-icon>
                            </button>
                        </div>
                    </div>
                </wui-list-tile>
            }
        </div>
    </div>
</wui-page>

<router-outlet></router-outlet>

<wui-menu class="compact" #rowMenu>
    <wui-menu-item icon="lead-pencil" routerLink="edit/{{this.dataJabatan[selectedIndex]?.idJabatan}}" queryParamsHandling="merge">Edit</wui-menu-item>
    <wui-menu-item icon="trash-can" (click)="hapus()">Hapus</wui-menu-item>
</wui-menu>