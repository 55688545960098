<wui-page>
    <wui-app-bar>
        <div class="wui-app-bar-leading">
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" wuiToggleDrawer>
                <wui-icon icon="menu"></wui-icon>
            </button>
        </div>
        <div class="wui-app-bar-title"></div>
        <div class="wui-app-bar-trailing"></div>
    </wui-app-bar>

    <div class="wui-page-inner">
        <div class="wui-page-container">
            <form [formGroup]="formUbahPassword" (submit)="submit()">
                <div class="mb-5 d-flex align-items-center">
                    <div class="flex-grow-1 font-28 font-weight-500">Ubah Password Akun</div>
                </div>

                <div class="row">
                    <div class="col">
                        <wui-form-field class="wui-form-field-boxed">
                            <label for="current">Password Saat Ini</label>
                            <input id="current" [type]="currentPassInput ? 'text' : 'password'" wuiInput formControlName="currentPassword">
                            <div class="wui-form-field-suffix" (click)="toggleFieldTextType('currentPass')">
                                @if (currentPassInput) {
                                    <wui-icon icon="eye-outline"></wui-icon>
                                } @else {
                                    <wui-icon icon="eye-off-outline"></wui-icon>
                                }
                            </div>
                        </wui-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <wui-form-field class="wui-form-field-boxed">
                            <label for="password">Password Baru</label>
                            <input id="password" [type]="newPassInput ? 'text' : 'password'" wuiInput formControlName="newPassword">
                            <div class="wui-form-field-suffix" (click)="toggleFieldTextType('newPass')">
                                @if (newPassInput) {
                                    <wui-icon icon="eye-outline"></wui-icon>
                                } @else {
                                    <wui-icon icon="eye-off-outline"></wui-icon>
                                }
                            </div>
                        </wui-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <wui-form-field class="wui-form-field-boxed">
                            <label for="confirm">Konfirmasi Password Baru</label>
                            <input id="confirm" [type]="confirmPassInput ? 'text' : 'password'" wuiInput formControlName="confirm">
                            <div class="wui-form-field-suffix" (click)="toggleFieldTextType('confirmPass')">
                                @if (confirmPassInput) {
                                    <wui-icon icon="eye-outline"></wui-icon>
                                } @else {
                                    <wui-icon icon="eye-off-outline"></wui-icon>
                                }
                            </div>
                        </wui-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="mt-5">
                            <button class="wui-button wui-button-primary" type="submit">Update Password</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</wui-page> 