import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PenggunaService } from 'src/app/services/pengguna.service';
import { WuiService, MessageService } from '@wajek/wui';
import { takeUntil, debounceTime } from 'rxjs';
import { PenggunaFilterComponent } from '../pengguna-filter/pengguna-filter.component';

@Component({
  selector: 'app-pengguna',
  templateUrl: './pengguna.component.html',
  styleUrl: './pengguna.component.scss'
})
export class PenggunaComponent implements OnInit{

  @ViewChild('searchInput', { static: false }) searchInput?: ElementRef;
  @ViewChild('formPenggunaFilter', { static: false }) formPenggunaFilter?: PenggunaFilterComponent;

  lastPage = false;
  isLoading = false;

  dataPengguna: Array<any> = [];
  selectedIndex = -1;

  count = 0;
  limit = 30;

  q = '';
  search: Subject<any> = new Subject();
  searchMode = false;

  page: any = 1;

  get offset() {
    return (this.page - 1) * this.limit; 
  }

  get pageCount() {
    return Math.ceil(this.count / this.limit);
  }

  idUnit: any;
  akses: any;

  private unsub: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private penggunaService: PenggunaService,
    private wuiService: WuiService,
    private messageService: MessageService
  ) { }

  async filter() {
    let res: any = await this.formPenggunaFilter?.open({
      akses: this.akses,
      idUnit: this.idUnit
    });
    console.log(res);
    if(res != null && res != 'close') {
      console.log('it works');
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          akses: res.akses == 'SEMUA AKSES' ? null : res.akses,
          idUnit: res.idUnit ?? null
        }
      });
    }
  }

  get isFiltered() {
    return this.idUnit != null || this.akses != null;
  }

  toggleSearch() {
    this.searchMode = !this.searchMode;

    setTimeout(() => {
      this.searchInput?.nativeElement.focus();
    }, 0);
    
    if(!this.searchMode) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          page: 1,
          search: null
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  first() {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: 1
      },
      queryParamsHandling: 'merge'
    });
  }

  prev() {
    if(this.page > 1) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          page: this.page - 1
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  next() {
    if(this.page < this.pageCount) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          page: this.page + 1
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  last() {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: this.pageCount
      },
      queryParamsHandling: 'merge'
    });
  }

  async hapus() {
    let confirm = await this.wuiService.dialog({
      title: 'Konfirmasi',
      message: 'Anda yakin untuk menghapus data pengguna ' + this.dataPengguna[this.selectedIndex]?.nmKaryawan + ' ?',
      buttons: ['Batal', 'Hapus']
    });
    if(confirm == 1) {
      try {
        this.wuiService.openLoading();
        await this.penggunaService.delete(this.dataPengguna[this.selectedIndex]?.idPengguna);
        this.wuiService.snackbar({
          label: 'Pengguna berhasil dihapus'
        });
        this.wuiService.closeLoading();
        this.dataPengguna.splice(this.selectedIndex, 1);
      } catch (e: any) {
        this.wuiService.closeLoading();
        this.wuiService.dialog({
          title: 'Error',
          message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
          buttons: ['OK']
        });
      }
    }
  }

  async resetPassword() {
    let confirm = await this.wuiService.dialog({
      title: 'Konfirmasi',
      message: 'Anda yakin untuk mereset password milik user ' + this.dataPengguna[this.selectedIndex]?.nmKaryawan + ' ?',
      buttons: ['Batal', 'Reset Password']
    });
    if(confirm == 1) {
      try {
        this.wuiService.openLoading();
        await this.penggunaService.resetPassword(this.dataPengguna[this.selectedIndex]?.idPengguna);
        this.wuiService.snackbar({
          label: 'Password pengguna berhasil direset'
        });
        this.wuiService.closeLoading();
      } catch (e: any) {
        this.wuiService.closeLoading();
        this.wuiService.dialog({
          title: 'Error',
          message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
          buttons: ['OK']
        });
      }
    }
  }

  async refresh() {
    try {
      this.dataPengguna = [];
      this.isLoading = true;
      let params: any = {
        offset: this.offset,
        limit: this.limit
      };
      if(this.q != null) { params.search = this.q; }
      if(this.akses != null) { params.akses = this.akses; }
      if(this.idUnit != null) { params.idUnit = this.idUnit; }
      let res: any = await this.penggunaService.result(params);
      this.dataPengguna = res.data;
      this.count = res.count;
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.mgs ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }

  ngOnInit(): void {
    this.messageService.get('pengguna:afterUpdate').pipe(takeUntil(this.unsub)).subscribe(res => {
      this.wuiService.snackbar({
        label: 'Pengguna berhasil diupdate'
      });
      this.refresh();
    });

    this.messageService.get('pengguna:afterInsert').pipe(takeUntil(this.unsub)).subscribe(res => {
      this.wuiService.snackbar({
        label: 'Pengguna berhasil ditambahkan'
      });
      this.refresh();
    });

    this.search.pipe(takeUntil(this.unsub), debounceTime(500)).subscribe(e => {
      if(this.searchMode) {
        this.router.navigate(['./'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            page: 1,
            search: e.target.value,
          },
          queryParamsHandling: 'merge'
        });
      } else { 
        this.q = '';
        return; 
      }
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.unsub)).subscribe(queryParams => {
      this.page = queryParams['page'] != null ? parseInt(queryParams['page']) : 1;
      this.q = queryParams['search'] ?? null;
      this.akses = queryParams['akses'] ?? null;
      this.idUnit = queryParams['idUnit'] ?? null;
      this.searchMode = this.q != null;
      this.refresh();
    });
  }

  ngOnDestroy() {
    this.unsub.next(null);
  }

}
