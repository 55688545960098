import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy{
  
  isLoggedIn: any = null;
  penggunaAktif: any;

  private unsub: Subject<any> = new Subject();

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  get initial() {
    return this.penggunaAktif?.nmKaryawan.substring(0, 1);
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/login']);
  }

  async ngOnInit() {
    this.authService.isLoggedIn.subscribe((isLoggedIn: any) => {
      this.isLoggedIn = isLoggedIn;
      if(this.isLoggedIn == null) return
      if(this.isLoggedIn == true) {
        this.penggunaAktif = this.authService.penggunaAktif.getValue();
      } else {
        this.isLoggedIn = false;
      }
    });

    this.authService.penggunaAktif.subscribe(penggunaAktif => {
      this.penggunaAktif = penggunaAktif;
    });

    try {
      await this.authService.init();
    } catch (e: any) {
      this.authService.signOut();
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy() {
    this.unsub.next(null);
  }

}
