import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { JabatanService } from 'src/app/services/jabatan.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WuiService, MessageService } from '@wajek/wui';
import { takeUntil, debounceTime } from 'rxjs';

@Component({
  selector: 'app-jabatan',
  templateUrl: './jabatan.component.html',
  styleUrl: './jabatan.component.scss'
})
export class JabatanComponent implements OnInit {
  
  @ViewChild('searchInput', { static: false }) searchInput?: ElementRef;

  lastPage = false;
  isLoading = false;

  dataJabatan: Array<any> = [];
  selectedIndex = -1;

  count = 0;
  limit = 30;

  q = '';
  search: Subject<any> = new Subject();
  searchMode = false;

  page = 1;

  get offset() {
    return (this.page - 1) * this.limit;
  }

  get pageCount() {
    return Math.ceil(this.count / this.limit);
  }

  private unsub: Subject<any> = new Subject();

  constructor(
    private jabatanService: JabatanService,
    private messageService: MessageService,
    private wuiService: WuiService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  toggleSearch() {
    this.searchMode = !this.searchMode;

    setTimeout(() => {
      this.searchInput?.nativeElement.focus();
    }, 0);

    if (!this.searchMode) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          page: 1,
          search: null
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  first() {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams:  {
        page: 1
      },
      queryParamsHandling: 'merge'
    });
  }

  prev() {
    if(this.page > 1) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams:  {
          page: this.page - 1
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  next() {
    if(this.page < this.pageCount) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams:  {
          page: this.page +  1
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  last() {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams:  {
        page: this.pageCount
      },
      queryParamsHandling: 'merge'
    });
  }

  async hapus() {
    let confirmResult = await this.wuiService.dialog({
      title: 'Konfirmasi',
      message: 'Anda yakin menghapus jabatan '+ this.dataJabatan[this.selectedIndex].nmJabatan +'?',
      buttons: ['Batal', 'Hapus']
    });
    if(confirmResult == 1) {
      try {
        this.wuiService.openLoading();
        await this.jabatanService.delete(this.dataJabatan[this.selectedIndex].idJabatan);
        this.wuiService.closeLoading();
        this.dataJabatan.splice(this.selectedIndex, 1);
        this.wuiService.snackbar({
          label: 'Data jabatan berhasil dihapus'
        });
      } catch(e :any) {
        this.wuiService.closeLoading();
        this.wuiService.dialog({
          title: 'Error',
          message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
          buttons: ['Ok']
        });
      }
    }
  }

  async refresh() {
    try {
      this.dataJabatan = [];
      this.isLoading = true;
      let res: any = await this.jabatanService.result({
        offset: this.offset,
        limit: this.limit
      });
      this.dataJabatan = res.data;
      this.count = res.count;
      this.isLoading = false;
    } catch(e: any) {
      this.isLoading = false;
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['Ok']
      });
    }
  }

  ngOnInit(): void {
    this.messageService.get('jabatan:afterInsert').subscribe(data => {
      this.dataJabatan.splice(0, 0, data);
    });

    this.messageService.get('jabatan:afterUpdate').subscribe(data => {
      let index = this.dataJabatan.findIndex((jabatan: any) => jabatan.idJabatan == data.idJabatan);
      if(index > -1) { // berarti ditemukan data yang sesuai
        this.dataJabatan[index].nmJabatan = data.nmJabatan;
        this.dataJabatan[index].akses = data.akses;
        this.dataJabatan[index].nmUnit = data.nmUnit;
      }
    });

    this.search.pipe(takeUntil(this.unsub), debounceTime(500)).subscribe(e => {
      if(this.searchMode) {
        this.router.navigate(['./'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            page: 1,
            search: e.target.value,
          },
          queryParamsHandling: 'merge'
        });
      } else { 
        this.q = '';
        return; 
      }
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.unsub)).subscribe(queryParams => {
      this.page = queryParams['page'] != null ? parseInt(queryParams['page']) : 1;
      this.q = queryParams['search'] ?? null;
      this.searchMode = this.q != null;
      this.refresh();
    });
  }

  ngOnDestroy() {
    this.unsub.next(null);
  }

}
