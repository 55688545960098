import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WuiService } from '@wajek/wui';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit{

  showPassword: boolean = false;
  isTyping: boolean = false;

  formLogin = new FormGroup({
    kodeUser: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  constructor(
    private wuiService: WuiService,
    private authService: AuthService,
    private router: Router
  ) { }

  onInput(event: Event): void {
    this.isTyping = (event.target as HTMLInputElement).value.length > 0;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  async submit() {
    if(this.formLogin.invalid) {
      this.wuiService.dialog({
        title: 'Error',
        message: 'Periksa kembali isian anda',
        buttons: ['Ok']
      });
      return;
    }

    try {
      this.wuiService.openLoading();
      await this.authService.signIn(this.formLogin.controls['kodeUser'].value, this.formLogin.controls['password'].value);
      this.wuiService.closeLoading();
      this.router.navigate(['/home']);
    } catch (e: any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: 'Periksa kembali isian anda',
        buttons: ['Ok']
      });
    }
  }

  ngOnInit(): void {}

}
