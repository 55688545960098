<form [formGroup]="formPengguna" (ngSubmit)="submit()">
    <wui-modal #modal [width]="650">
        <div class="wui-modal-header">
            <div class="d-flex justify-content-between align-items-center">
                <div>{{aksi == 'insert' ? 'Pengguna Baru' : 'Edit Pengguna'}}</div>
                <button class="wui-button wui-button-smooth wui-button-icon" type="button" (click)="close()">
                    <wui-icon icon="close"></wui-icon>
                </button>
            </div>
        </div>

        <div class="wui-modal-content pt-2">
            <div class="row">
                <div class="col-md-6">
                    <wui-form-field class="wui-form-field-boxed">
                        <label for="kodeKaryawan">Kode Karyawan (Optional)</label>
                        <input id="kodeKaryawan" type="text" wuiInput formControlName="kodeKaryawan" #inputFocus>
                    </wui-form-field>
                </div>
                <div class="col-md-6">
                    <wui-form-field class="wui-form-field-boxed">
                        <label for="isAktif">Status User</label>
                        <select name="isAktif" id="isAktifPengguna" wuiInput formControlName="isAktif">
                            <option value="1">Aktif</option>
                            <option value="0">Tidak Aktif</option>
                        </select>
                    </wui-form-field>
                </div>
            </div>

            <wui-form-field class="wui-form-field-boxed">
                <label for="nmKaryawan">Nama Karyawan</label>
                <input id="nmKaryawan" type="text" wuiInput formControlName="nmKaryawan">
            </wui-form-field>

            <div class="row">
                <div class="col-md-6">
                    <wui-form-field class="wui-form-field-boxed">
                        <label for="kodeUser">Username</label>
                        <input id="kodeUser" type="text" wuiInput formControlName="kodeUser">
                    </wui-form-field>
                </div>
                <div class="col-md-6">
                    <wui-form-field class="wui-form-field-boxed">
                        <label for="akses">Akses</label>
                        <select name="akses" id="akses" wuiInput formControlName="akses" (change)="aksesChange($event)">
                            <option value="ADMINISTRATOR">Administrator</option>
                            <option value="PELAKSANA">Pelaksana</option>
                            <option value="SPV">SPV / Karu</option>
                            <option value="MANAGER">Manager / QC</option>
                            <option value="DIREKTUR">Direktur</option>
                        </select>
                    </wui-form-field>
                </div>
            </div>

            @if (formPengguna.controls['akses'].value != 'ADMINISTRATOR') {
                <div class="row">
                    <div class="col-md-6">
                        <wui-form-field class="wui-form-field-boxed">
                            <label for="idUnit">Unit</label>
                            <input id="idUnit" type="text" wuiInput readonly [ngModel]="labelUnit" [ngModelOptions]="{standalone: true}">
                            <div class="wui-form-field-suffix">
                                <button class="wui-button wui-button-icon wui-button-smooth" type="button" (click)="pilihUnit()">
                                    <wui-icon icon="chevron-down"></wui-icon>
                                </button>
                            </div>
                        </wui-form-field>
                    </div>
                    <div class="col-md-6">
                        <wui-form-field class="wui-form-field-boxed">
                            <label for="idJabatan">Jabatan</label>
                            <input id="idJabatan" type="text" wuiInput readonly [ngModel]="labelJabatan" [ngModelOptions]="{standalone: true}">
                            <div class="wui-form-field-suffix">
                                <button class="wui-button wui-button-icon wui-button-smooth" type="button" (click)="pilihJabatan()">
                                    <wui-icon icon="chevron-down"></wui-icon>
                                </button>
                            </div>
                        </wui-form-field>
                    </div>
                </div>
            }

            <div class="row">
                <div class="col-md-6">
                    <wui-form-field class="wui-form-field-boxed">
                        <label for="password">Password</label>
                        <input id="password" [type]="passInputType ? 'text' : 'password'" wuiInput formControlName="password">
                        <div class="wui-form-field-suffix" (click)="toggleFieldTextType('password')">
                            @if (passInputType) {
                                <wui-icon icon="eye-outline"></wui-icon>
                            } @else {
                                <wui-icon icon="eye-off-outline"></wui-icon>
                            }
                        </div>
                    </wui-form-field>
                </div>
                <div class="col-md-6">
                    <wui-form-field class="wui-form-field-boxed">
                        <label for="confirm">Konfirmasi</label>
                        <input id="confirm" [type]="confirmPassInputType ? 'text' : 'password'" wuiInput formControlName="confirm">
                        <div class="wui-form-field-suffix" (click)="toggleFieldTextType('confirmPassword')">
                            @if (confirmPassInputType) {
                                <wui-icon icon="eye-outline"></wui-icon>
                            } @else {
                                <wui-icon icon="eye-off-outline"></wui-icon>
                            }
                        </div>
                    </wui-form-field>
                </div>
            </div>
        </div>

        <div class="wui-modal-footer">
            <div class="d-flex justify-content-between align-items-center">
                <button class="wui-button" type="button" (click)="close()">Batal</button>
                <button class="wui-button wui-button-primary" type="submit">Simpan</button>
            </div>
        </div>
    </wui-modal>
</form>

<app-jabatan-cari #formCariJabatan></app-jabatan-cari>
<app-unit-cari #formCariUnit></app-unit-cari>