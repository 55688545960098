import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WuiModule } from '@wajek/wui';
import { HomeComponent } from './pages/home/home.component';
import { PenggunaComponent } from './pages/pengguna/pengguna/pengguna.component';
import { PenggunaFormComponent } from './pages/pengguna/pengguna-form/pengguna-form.component';
import { HttpService } from './services/http.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UnitComponent } from './pages/unit/unit/unit.component';
import { UnitFormComponent } from './pages/unit/unit-form/unit-form.component';
import { JabatanComponent } from './pages/jabatan/jabatan/jabatan.component';
import { JabatanFormComponent } from './pages/jabatan/jabatan-form/jabatan-form.component';
import { KerjasamaComponent } from './pages/kerjasama/kerjasama/kerjasama.component';
import { KerjasamaFormComponent } from './pages/kerjasama/kerjasama-form/kerjasama-form.component';
import { LoginComponent } from './pages/login/login.component';
import { JabatanCariComponent } from './pages/jabatan/jabatan-cari/jabatan-cari.component';
import { UnitCariComponent } from './pages/unit/unit-cari/unit-cari.component';
import { LaporanComponent } from './pages/laporan/laporan/laporan.component';
import { LaporanFormComponent } from './pages/laporan/laporan-form/laporan-form.component';
import { LaporanItemFormComponent } from './pages/laporan/laporan-item-form/laporan-item-form.component';
import { LaporanTerkaitComponent } from './pages/laporan/laporan-terkait/laporan-terkait.component';
import { LaporanTerkaitFormComponent } from './pages/laporan/laporan-terkait-form/laporan-terkait-form.component';
import { LaporanDetailComponent } from './pages/laporan/laporan-detail/laporan-detail.component';
import { LaporanTerkaitDetailComponent } from './pages/laporan/laporan-terkait-detail/laporan-terkait-detail.component';
import { LaporanFilterComponent } from './pages/laporan/laporan-filter/laporan-filter.component';
import { SettingPasswordComponent } from './pages/setting/setting-password/setting-password.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PenggunaFilterComponent } from './pages/pengguna/pengguna-filter/pengguna-filter.component'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PenggunaComponent,
    PenggunaFormComponent,
    UnitComponent,
    UnitFormComponent,
    JabatanComponent,
    JabatanFormComponent,
    KerjasamaComponent,
    KerjasamaFormComponent,
    LoginComponent,
    JabatanCariComponent,
    UnitCariComponent,
    LaporanComponent,
    LaporanFormComponent,
    LaporanItemFormComponent,
    LaporanTerkaitComponent,
    LaporanTerkaitFormComponent,
    LaporanDetailComponent,
    LaporanTerkaitDetailComponent,
    LaporanFilterComponent,
    SettingPasswordComponent,
    PenggunaFilterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    WuiModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    NgxChartsModule,
    BrowserAnimationsModule
  ],
  providers: [{
    provide: 'apiUrl',
    useValue: '/api/'
  }, {
    provide: 'HTTP_SERVICE',
    useValue: HttpService
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
