<wui-page>
    <wui-app-bar>
        <div class="wui-app-bar-leading">
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" routerLink="../" queryParamsHandling="merge">
                <wui-icon icon="arrow-left"></wui-icon>
            </button>
        </div>
        <div class="wui-app-bar-title"></div>
        <div class="wui-app-bar-trailing">
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="refresh()">
                <wui-icon icon="refresh"></wui-icon>
            </button>
        </div>
    </wui-app-bar>

    <div class="wui-page-inner">
        <div class="wui-page-container">
            <h3 class="mb-5">Detail Laporan</h3>

            <div class="row">
                <div class="col-md-{{laporan?.jenisLaporan != 'INFO' ? '4' : '6'}} col-sm-12 mb-3">
                    <div class="font-14 font-weight-bold">Tanggal</div>
                    <div>{{laporan?.tanggal.date | date:'dd MMMM yyyy HH:mm'}}</div>
                </div>
                <div class="col-md-{{laporan?.jenisLaporan != 'INFO' ? '4' : '6'}} col-sm-12 mb-3">
                    <div class="font-14 font-weight-bold">Jenis Laporan</div>
                    <div><span class="status {{laporan?.jenisLaporan == 'TANTANGAN' ? 'text-bg-danger' : (laporan?.jenisLaporan == 'HUMAN ERROR' ? 'text-bg-warning' : 'text-bg-info')}}">{{laporan?.jenisLaporan}}</span></div>
                </div>
                @if (laporan?.jenisLaporan != 'INFO') {
                    <div class="col-md-4 col-sm-12 mb-3">
                        <div class="font-14 font-weight-bold">Status Laporan</div>
                        <div class="status {{laporan?.statusGenerated == 'SELESAI' ? 'text-bg-success' : (laporan?.statusGenerated == 'SEDANG DIKERJAKAN' ? 'text-bg-warning' : 'text-bg-danger')}}">{{laporan?.statusGenerated}}</div>
                    </div>
                }
            </div>

            <div class="row">
                <div class="col-md-{{laporan?.jenisLaporan != 'INFO' ? '4' : '6'}} col-sm-12 mb-3">
                    <div class="font-14 font-weight-bold">Dibuat oleh</div>
                    <div>{{laporan?.nmKaryawan}}</div>
                </div>
                <div class="col-md-{{laporan?.jenisLaporan != 'INFO' ? '4' : '6'}} col-sm-12 mb-3">
                    <div class="font-14 font-weight-bold">Unit Pelapor</div>
                    <div>{{laporan?.nmUnit}}</div>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col">
                    <div class="font-14 font-weight-bold">Uraian</div>
                    <div>{{laporan?.uraian}}</div>
                </div>
            </div>

            <div class="alert text-bg-info font-14 mb-4 font-weight-500 d-flex align-items-center gap-3">
                <wui-icon icon="information-box"></wui-icon> Unit terkait harus menyelesaikan laporan terlebih dahulu agar status laporan dapat berubah menjadi <span class="status text-bg-success">SELESAI</span>
            </div>

            @if (laporan?.jenisLaporan != 'INFO') {
                <div class="d-flex mb-4 align-items-center">
                    <div class="flex-grow-1 font-20">
                        <wui-icon icon="domain" class="me-2"></wui-icon>
                        <span class="font-weight-500">Unit Terkait</span>
                    </div>
                    <button *ngIf="penggunaAktif.akses !== 'PELAKSANA' && penggunaAktif.akses !== 'SPV'" class="wui-button wui-button-primary" (click)="tambahDetail()">
                        <wui-icon icon="plus" class="me-2"></wui-icon> Unit Terkait
                    </button>
                </div>
    
                <div class="table-responsive-sm table-hover">
                    <table class="table align-middle">
                        <thead>
                            <tr>
                                <th scope="col">Status</th>
                                <th scope="col">Unit</th>
                                <th scope="col">Uraian</th>
                                <th scope="col">Tindak Lanjut</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        @for (laporanUnit of dataLaporanUnit; track $index;) {
                            <tbody>
                                <tr (click)="selectedIndex = $index">
                                    <td scope="row">
                                        <div class="status text-wrap {{laporanUnit?.status == 'SELESAI' ? 'text-bg-success' : (laporanUnit?.status == 'SEDANG DIKERJAKAN' ? 'text-bg-warning' : 'text-bg-danger')}}">{{laporanUnit?.status}}</div>
                                    </td>
                                    <td>{{laporanUnit?.nmUnit}}</td>
                                    <td>{{laporanUnit.uraian ?? '-'}}</td>
                                    <td>{{laporanUnit.tindakLanjut ?? '-'}}</td>
                                    <td class="text-end">
                                        <button *ngIf="penggunaAktif.akses != 'PELAKSANA' && penggunaAktif.akses != 'SPV'" class="wui-button wui-button-icon wui-button-smooth" [wuiMenu]="rowMenu">
                                            <wui-icon icon="dots-vertical"></wui-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        }
    
                        @if (isLoading === true) {
                            <div class="p-5 text-center">
                                <wui-loading mode="circular"></wui-loading>
                            </div>
                        }
                    </table>
                </div>   
            }
        </div>
    </div>
</wui-page>

<wui-menu class="compact" #rowMenu>
    <wui-menu-item icon="lead-pencil" (click)="editDetail()">Ubah</wui-menu-item>
    <wui-menu-item icon="trash-can" (click)="hapusDetail()">Hapus</wui-menu-item>
</wui-menu>

<app-laporan-item-form #formItem></app-laporan-item-form>