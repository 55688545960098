import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService, WuiService } from '@wajek/wui';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UnitCariComponent } from '../../unit/unit-cari/unit-cari.component';
import { LaporanUnitService } from 'src/app/services/laporan-unit.service';

@Component({
  selector: 'app-laporan-terkait-form',
  templateUrl: './laporan-terkait-form.component.html',
  styleUrl: './laporan-terkait-form.component.scss'
})
export class LaporanTerkaitFormComponent {

  @ViewChild('formCariUnit', {static : true}) formCariUnit?: UnitCariComponent;

  aksi = 'update';
  idLaporanUnit: any;

  formLaporanTerkait = new UntypedFormGroup({
    idUnit: new UntypedFormControl(null, Validators.required),
    uraian: new UntypedFormControl(null),
    tindakLanjut: new UntypedFormControl(null),
    status: new UntypedFormControl(null, Validators.required),
  });

  unit: any = null;
  get labelUnit() {
    return this.unit?.nmUnit ?? null;
  }

  tanggalLaporan: any = null;
  get labelTglLaporan() {
    return this.tanggalLaporan != null ? this.datePipe.transform(this.tanggalLaporan, 'yyyy-MM-dd HH:mm') : this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
  }

  jenisLaporan: any = null;

  constructor(
    private wuiService: WuiService,
    private laporanUnitService: LaporanUnitService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe
  ) { }

  back() {
    this.router.navigate([('../../')], {
      relativeTo: this.activatedRoute
    });
  }

  async pilihUnit() {
    let unit: any = await this.formCariUnit?.open();
    if(unit != 'close' && unit != null) {
      this.formLaporanTerkait.controls['idUnit'].setValue(unit.idUnit);
      this.unit = unit;
    }
  }

  async submit() {
    if(this.formLaporanTerkait.invalid) {
      this.wuiService.dialog({
        title: 'Error',
        message: 'Periksa kembali isian anda',
        buttons: ['OK']
      });
      return;
    }

    try {
      this.wuiService.openLoading();
      let data: any = this.formLaporanTerkait.value;
      await this.laporanUnitService.update(this.idLaporanUnit, data);
      data.idLaporanUnit = this.idLaporanUnit;
      this.wuiService.snackbar({
        label: 'Data Laporan Terkait berhasil diupdate'
      });
      this.messageService.set('laporanTerkait:afterUpdate', data);
      this.wuiService.closeLoading();
      this.back();
    } catch (e: any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }

  async ngOnInit() {
    this.idLaporanUnit = this.activatedRoute.snapshot.params['idLaporanUnit'];
    try {
      this.wuiService.openLoading();
      this.aksi = 'edit';
      let res = await this.laporanUnitService.row(this.idLaporanUnit);
      this.formLaporanTerkait.controls['idUnit'].setValue(res.idUnit);
      this.formLaporanTerkait.controls['uraian'].setValue(res.uraian);
      this.formLaporanTerkait.controls['tindakLanjut'].setValue(res.tindakLanjut);
      this.formLaporanTerkait.controls['status'].setValue(res.status);
      this.unit = {
        idUnit: res.idUnit,
        nmUnit: res.nmUnit,
        kodeUnit: res.kodeUnit
      };
      this.jenisLaporan = res.jenisLaporan;
      this.wuiService.closeLoading();
    } catch (e:any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }

}
