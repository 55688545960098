import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalComponent, WuiService } from '@wajek/wui';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs';
import { UnitService } from 'src/app/services/unit.service';

@Component({
  selector: 'app-unit-cari',
  templateUrl: './unit-cari.component.html',
  styleUrl: './unit-cari.component.scss'
})
export class UnitCariComponent {

  @ViewChild('modal', { static: true }) modal?: ModalComponent;
  @ViewChild('searchInput', { static: false }) searchInput?: ElementRef;

  isLoading = false;
  dataUnit: Array<any> = [];

  page = 1;
  limit = 30;
  count = 0;

  q = '';
  search: Subject<any> = new Subject();

  private result: Subject<any> = new Subject();
  private unsub: Subject<any> = new Subject();

  constructor(
    private wuiService: WuiService,
    private unitService: UnitService
  ) { }

  // async tambahUnit() {
  //   let unit: any = await this.formUnit?.insert();
  //   if(unit != 'close' && unit != null) {
  //     this.result.next(unit);
  //   }
  // }

  select(unit: any) {
    this.result.next(unit);
  }

  first() {
    this.page = 1;
    this.refresh();
  }

  prev() {
    if(this.page > 1) {
      this.page--;
      this.refresh();
    }
  }

  next() {
    if(this.page < this.pageCount) {
      this.page++;
      this.refresh();
    }
  }

  last() {
    this.page = this.pageCount;
    this.refresh();
  }

  get offset() {
    return (this.page - 1) * this.limit;
  }

  get pageCount() {
    return Math.ceil(this.count / this.limit);
  }

  async refresh(tipe: any = null) {
    try {
      this.dataUnit = [];
      this.isLoading = true;
      let params: any = {
        offset: this.offset,
        limit: this.limit,
        search: this.q
      };
      if(tipe != null) params.tipe = tipe;
      let res: any = await this.unitService.result(params);
      this.dataUnit = res.data;
      this.count = res.count;
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = true;
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }

  open(tipe: any = null): Promise<any> {
    return new Promise((resolve) => {
      this.modal?.open();
      this.refresh(tipe);

      let sub = this.result.subscribe(result => {
        resolve(result);
        this.modal?.close();
        sub.unsubscribe();
      })
    });
  }

  close() {
    this.result.next('close');
  }

  ngOnInit(): void {
    this.search.pipe(takeUntil(this.unsub), debounceTime(500)).subscribe(e => {
      this.q = e.target.value;
      this.page = 1;
      this.refresh();
    });
  }

  ngOnDestroy() {
    this.unsub.next(null);
  }

}
