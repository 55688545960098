import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WuiService } from '@wajek/wui';
import { LaporanService } from 'src/app/services/laporan.service';
import { LaporanItemFormComponent } from '../laporan-item-form/laporan-item-form.component';
import { LaporanUnitService } from 'src/app/services/laporan-unit.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-laporan-detail',
  templateUrl: './laporan-detail.component.html',
  styleUrl: './laporan-detail.component.scss'
})
export class LaporanDetailComponent implements OnInit {

  @ViewChild('formItem', {static: true}) formItem?: LaporanItemFormComponent;

  aksi = ''
  penggunaAktif: any;
  isLoading = false;
  idLaporan: any;
  laporan: any;

  dataLaporanUnit: Array<any> = [];
  selectedIndex: any = -1;

  formLaporanUnit = new UntypedFormGroup({
    idUnit: new UntypedFormControl(null, Validators.required),
    uraian: new UntypedFormControl(null, Validators.required),
    tindakLanjut: new UntypedFormControl(null, Validators.required)
  });

  constructor(
    private laporanService: LaporanService,
    private laporanUnitService: LaporanUnitService,
    private activatedRoute: ActivatedRoute,
    private wuiService: WuiService,
    private router: Router,
    private authService: AuthService
  ) {}

  back() {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge'
    });
  }

  async tambahDetail() {
    let item: any = await this.formItem?.insert();
    if(item != 'close' && item != null) {
      this.dataLaporanUnit.push(item);
      this.refresh();
    }
  }

  async editDetail() {
    let item: any = await this.formItem?.edit(this.dataLaporanUnit[this.selectedIndex]);
    if(item != 'close' && item != null) {
      this.dataLaporanUnit[this.selectedIndex] = item;
      this.refresh();
    }
  }

  async hapusDetail() {
    let res: any = await this.wuiService.dialog({
      title: 'Konfrimasi',
      message: 'Anda yakin ingin menghapus Laporan Unit terpilih ?',
      buttons: ['Batal', 'Hapus']
    });
    if(res == 1) {
      try {
        this.wuiService.openLoading();
        await this.laporanUnitService.delete(this.dataLaporanUnit[this.selectedIndex].idLaporanUnit);
        this.wuiService.closeLoading();
        this.dataLaporanUnit.splice(this.selectedIndex, 1);
        this.wuiService.snackbar({
          label: 'Data Laporan Unit berhasil dihapus'
        });
      } catch (e: any) {
        this.wuiService.closeLoading();
        this.wuiService.dialog({
          title: 'Error',
          message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
          buttons: ['Ok']
        });
      }
    }
  }

  async refresh() {
    try {
      this.dataLaporanUnit = [];
      this.isLoading = true;
      this.laporan = await this.laporanService.row(this.idLaporan);
      this.dataLaporanUnit = this.laporan?.detail;
      this.isLoading = false;
    } catch(e: any) {
      this.isLoading = false;
      this.wuiService.dialog({
        title: "Error",
        message: e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['Ok']
      });
    }
  }

  ngOnInit() {
    this.authService.penggunaAktif.subscribe(penggunaAktif => {
      this.penggunaAktif = penggunaAktif;
    });

    this.idLaporan = this.activatedRoute.snapshot.params['idLaporan'];
    this.refresh();
  }

}
