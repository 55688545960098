import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class JabatanService {

  constructor(
    private httpService: HttpService,
    @Inject('apiUrl') private apiUrl: string
  ) { }
  async result(params: any = null) {
    return await this.httpService.get(this.apiUrl + 'jabatan', {
      params: params
    })
  }

  async row(idJabatan: any) {
    return await this.httpService.get(this.apiUrl + 'jabatan/' + idJabatan);
  }

  async insert(data: any) {
    return await this.httpService.post(this.apiUrl + 'jabatan', data);
  }

  async update(idJabatan: any, data: any) {
    return await this.httpService.patch(this.apiUrl + 'jabatan/' + idJabatan, data);
  }

  async delete(idJabatan: any) {
    return await this.httpService.delete(this.apiUrl + 'jabatan/' + idJabatan);
  }
}
