<form [formGroup]="formFilter" (submit)="submit()">
    <wui-modal #modal [width]="450">
        <div class="wui-modal-header">
            <div class="d-flex justify-content-between align-items-center">
                <div>Filter</div>
                <button class="wui-button wui-button-smooth wui-button-icon" type="button" (click)="close()">
                    <wui-icon icon="close"></wui-icon>
                </button>
            </div>
        </div>
    
        <div class="wui-modal-content pt-2">
            <wui-form-field class="wui-form-field-boxed">
                <label for="awal">Tanggal Awal</label>
                <input id="awal" type="date" wuiInput formControlName="awal">
            </wui-form-field>
            
            <wui-form-field class="wui-form-field-boxed">
                <label for="akhir">Tanggal Akhir</label>
                <input id="akhir" type="date" wuiInput formControlName="akhir">
            </wui-form-field>

            @if (penggunaAktif.akses != 'PELAKSANA' && penggunaAktif.akses != 'SPV') {
                @if (endPoint != '/laporanTerkait') {
                    <wui-form-field class="wui-form-field-boxed">
                        <label for="idUnit">Unit {{endPoint === '/laporan' ? 'Pelapor' : 'Terkait'}}</label>
                        <input id="idUnit" type="text" wuiInput readonly [ngModel]="labelUnit" [ngModelOptions]="{standalone: true}">
                        <div class="wui-form-field-suffix">
                            <button *ngIf="this.formFilter.controls['idUnit'].value != null" class="wui-button wui-button-icon wui-button-smooth" type="button" (click)="resetUnit()">
                                <wui-icon icon="window-close"></wui-icon>
                            </button>
                            <button class="wui-button wui-button-icon wui-button-smooth" type="button" (click)="pilihUnit()">
                                <wui-icon icon="chevron-down"></wui-icon>
                            </button>
                        </div>                       
                    </wui-form-field>
                }
            }

            @if (endPoint != '/laporanTerkait') {
                <wui-form-field class="wui-form-field-boxed">
                    <label for="jenis">Jenis Laporan</label>
                    <select name="jenis" id="jenis" wuiInput formControlName="jenis">
                        <option value="SEMUA JENIS">Semua Jenis</option>
                        <option value="INFO">Info</option>
                        <option value="TANTANGAN">Tantangan</option>
                        <option value="HUMAN ERROR">Human Error</option>
                    </select>
                </wui-form-field>
            }

            <wui-form-field class="wui-form-field-boxed">
                <label for="status">Status Laporan</label>
                <select name="status" id="status" wuiInput formControlName="status">
                    <option value="SEMUA STATUS">Semua Status</option>
                    <option value="BELUM DIKERJAKAN">Belum Dikerjakan</option>
                    <option value="SEDANG DIKERJAKAN">Sedang Dikerjakan</option>
                    <option value="SELESAI">Selesai</option>
                </select>
            </wui-form-field>
        </div>
    
        <div class="wui-modal-footer">
            <div class="d-flex justify-content-between align-items-center">
                <button class="wui-button" type="button" (click)="reset()">Reset</button>
                <button class="wui-button wui-button-primary" type="submit">Filter</button>
            </div>
        </div>
    </wui-modal>
</form>

<app-unit-cari #formCariUnit></app-unit-cari>