import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ModalComponent } from '@wajek/wui';
import { Router } from '@angular/router';
import { UnitCariComponent } from '../../unit/unit-cari/unit-cari.component';
import { UnitService } from 'src/app/services/unit.service';

@Component({
  selector: 'app-pengguna-filter',
  templateUrl: './pengguna-filter.component.html',
  styleUrl: './pengguna-filter.component.scss'
})
export class PenggunaFilterComponent {

  @ViewChild('modal', {static: true}) modal?: ModalComponent;
  @ViewChild('formCariUnit', {static: true}) formCariUnit?: UnitCariComponent; 

  private result: Subject<any> = new Subject();

  unit: any;
  get labelUnit() {
    return this.unit?.nmUnit ?? 'Semua Unit';
  }

  formFilter = new UntypedFormGroup({
    idUnit: new UntypedFormControl(),
    akses: new UntypedFormControl()
  });

  constructor(
    private unitService: UnitService
  ) { }

  open(filter: any) {
    return new Promise(async (resolve) => {
      this.formFilter.controls['idUnit'].setValue(filter.idUnit ?? null);
      this.formFilter.controls['akses'].setValue(filter.akses ?? 'SEMUA AKSES');
      this.modal?.open();

      if(filter.idUnit != null) {
        this.unit = await this.unitService.row(filter.idUnit);
      } else {
        this.unit = null;
      }

      let sub = this.result.subscribe(res => {
        sub.unsubscribe();
        this.modal?.close();
        resolve(res); 
      })
    })
  }

  async pilihUnit() {
    let unit = await this.formCariUnit?.open();
    if(unit != null && unit != 'close') {
      this.formFilter.controls['idUnit'].setValue(unit.idUnit);
      this.unit = unit;
    }
  }

  resetUnit() {
    this.unit = null;
    this.formFilter.controls['idUnit'].setValue(null);
  }

  close() {
    this.result.next('close');
  }

  reset() {
    this.unit = null;
    this.result.next({
      idUnit: null,
      akses: 'SEMUA AKSES'
    });
  }

  submit() {
    this.result.next({
      idUnit: this.formFilter.controls['idUnit'].value,
      akses: this.formFilter.controls['akses'].value
    });
  }

}
