import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent, WuiService, MessageService } from '@wajek/wui';
import { Subject } from 'rxjs';
import { KerjasamaService } from 'src/app/services/kerjasama.service';

@Component({
  selector: 'app-kerjasama-form',
  templateUrl: './kerjasama-form.component.html',
  styleUrl: './kerjasama-form.component.scss'
})
export class KerjasamaFormComponent {

  // @ViewChild('logo') private logo: ElementRef;

  aksi = 'insert';
  idKerjasama = 0;
  formKerjasama = new UntypedFormGroup({
    nmKerjasama: new UntypedFormControl(null, Validators.required),
    tglAwal: new UntypedFormControl(null, Validators.required),
    tglAkhir: new UntypedFormControl(null, Validators.required),
    logo: new UntypedFormControl(null),
    isAktif: new UntypedFormControl(1),
  })

  dataDetail: Array<any> = [];
  selectedIndex = -1;

  private $result: Subject<any> = new Subject();

  constructor(
    private wuiService: WuiService,
    private kerjasamaService: KerjasamaService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  back() {
    this.router.navigate([(this.aksi == 'insert' ? '../' : '../../')], {
      relativeTo: this.activatedRoute
    });
  }

  close() {
    this.$result.next('close');
  }

  // insert() {
  //   return new Promise((resolve) => {
  //     this.aksi = 'insert';
  //     this.formKerjasama.controls['nmKerjasama'].setValue(null);
  //     this.formKerjasama.controls['tglAwal'].setValue(null);
  //     this.formKerjasama.controls['tglAkhir'].setValue(null);
  //     this.formKerjasama.controls['logo'].setValue(null);
  //     this.formKerjasama.controls['isAktif'].setValue(null);

  //     this.modal?.open();
  //     let sub = this.$result.subscribe(result => {
  //       resolve(result);
  //       this.modal?.close();
  //       sub.unsubscribe();
  //     });
  //   });
  // }

  async submit() {
    if (this.formKerjasama.invalid) {
      this.wuiService.dialog({
        title: 'Error',
        message: 'Periksa kembali isian anda',
        buttons: ['OK']
      });
      return;
    };

    try {
      this.wuiService.openLoading();
      let data: any = {
        nmKerjasama: this.formKerjasama.controls['nmKerjasama'].value,
        tglAwal: this.formKerjasama.controls['tglAwal'].value,
        tglAkhir: this.formKerjasama.controls['tglAkhir'].value,
        logo: this.formKerjasama.controls['logo'].value,  
        isAktif: this.formKerjasama.controls['isAktif'].value
      };

      if (this.aksi == 'insert') {
        let res: any = await this.kerjasamaService.insert(data);
        data.idKerjasama = res.idKerjasama;
      } else {
        await this.kerjasamaService.update(this.idKerjasama, data);
        data.idKerjasama = this.idKerjasama;
      }
      this.wuiService.closeLoading();
      this.$result.next(data);
    } catch (e: any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }

  // async ngOnInit() {
  //   let idKerjasama = this.activatedRoute.snapshot.params['idKerjasama'] ?? null;
  //   if (idKerjasama !== null) {
  //     let result = await this.edit(idKerjasama);
  //     if (result !== 'close' && result !== null) {
  //       this.messageService.set('pengguna:afterUpdate', result);
  //     }
  //   } else {
  //     let result = await this.insert();
  //     if (result !== 'close' && result !== null) {
  //       this.messageService.set('pengguna:afterInsert', result);
  //     }
  //   }
  //   this.router.navigate([idPengguna !== null ? '../../' : '../'], {
  //     relativeTo: this.activatedRoute,
  //     queryParamsHandling: 'merge'
  //   });
  // }

}
