import { Component, OnInit } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { SummaryService } from 'src/app/services/summary.service';
import { WuiService } from '@wajek/wui';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit{

  laporanAll = 0;
  laporanInfo = 0;
  laporanTantangan = 0;
  laporanHumanError = 0;

  constructor(
    private summaryService: SummaryService,
    private wuiService: WuiService
  ) { }

  async refresh() {
    try {
      this.wuiService.openLoading();
      let lapAll: any = await this.summaryService.sumLaporan();
      let lapInfo: any = await this.summaryService.sumLaporan({ filter: 'JENIS', value: 'INFO' });
      let lapTantangan: any = await this.summaryService.sumLaporan({ filter: 'JENIS', value: 'TANTANGAN' });
      let lapHumanError: any = await this.summaryService.sumLaporan({ filter: 'JENIS', value: 'HUMAN ERROR' });
      // let lapInfo: any = await this.summaryService.sumByJenis({ jenis: 'INFO' });
      // let lapTantangan: any = await this.summaryService.sumByJenis({ jenis: 'TANTANGAN' });
      // let lapHumanError: any = await this.summaryService.sumByJenis({ jenis: 'HUMAN ERROR' });
      this.laporanAll = lapAll?.jmlLaporan;
      this.laporanInfo = lapInfo?.jmlLaporan;
      this.laporanTantangan = lapTantangan?.jmlLaporan;
      this.laporanHumanError = lapHumanError?.jmlLaporan;
      console.log(this.laporanAll);
      this.wuiService.closeLoading();
    } catch (e: any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['Ok']
      });
    }
  }

  ngOnInit(): void {
    this.refresh();
  }

  // set view size for chart
  // view: [number, number] = [700, 400];

  // options params / atributes for line chart
  // showXAxis: boolean = true;
  // showYAxis: boolean = true;
  // gradient: boolean = false;
  // showLegend: boolean = true;
  // showXAxisLabel: boolean = true;
  // xAxisLabel: string = 'Country';
  // showYAxisLabel: boolean = true;
  // yAxisLabel: string = 'Population';
  // autoScale: boolean = true;

  // sample color scheme for line chart
  // colorScheme: Color = {
  //   name: 'customScheme',
  //   selectable: true,
  //   group: ScaleType.Ordinal,
  //   domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  // };

  // sample data line chart
  // data = [
  //   {
  //     "name": "Germany",
  //     "series": [
  //       {
  //         "name": "2010",
  //         "value": 7300000
  //       },
  //       {
  //         "name": "2011",
  //         "value": 8940000
  //       },
  //       {
  //         "name": "2012",
  //         "value": 8940000
  //       },
  //       {
  //         "name": "2013",
  //         "value": 8940000
  //       },
  //       {
  //         "name": "2014",
  //         "value": 8940000
  //       }
  //     ]
  //   },
  // ];

}
