<wui-page>
    <wui-app-bar>
        <div class="wui-app-bar-leading">
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="back()">
                <wui-icon icon="arrow-left"></wui-icon>
            </button>
        </div>
        <div class="wui-app-bar-title"></div>
        <div class="wui-app-bar-trailing">
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="refresh()">
                <wui-icon icon="refresh"></wui-icon>
            </button>
        </div>
    </wui-app-bar>

    <div class="wui-page-inner">
        <div class="wui-page-container">
            <h3 class="mb-5">Detail Laporan Terkait</h3>

            <div class="d-flex mb-4 align-items-center">
                <div class="flex-grow-1 font-20">
                    <wui-icon icon="home-city-outline" class="me-2"></wui-icon>
                    <span class="font-weight-500">Laporan Utama</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 col-sm-12 mb-3">
                    <div class="font-14 font-weight-bold">Tanggal</div>
                    <div>{{laporan?.tanggal.date | date:'dd MMMM yyyy HH:mm'}}</div>
                </div>
                <div class="col-md-4 col-sm-12 mb-3">
                    <div class="font-14 font-weight-bold">Jenis Laporan</div>
                    <div><span class="status {{laporan?.jenisLaporan == 'TANTANGAN' ? 'text-bg-danger' : (laporan?.jenisLaporan == 'HUMAN ERROR' ? 'text-bg-warning' : 'text-bg-info')}}">{{laporan?.jenisLaporan}}</span></div>
                </div>
                <div class="col-md-4 col-sm-12 mb-3">
                    <div class="font-14 font-weight-bold">Status Laporan Utama</div>
                    <div class="status {{laporan?.statusGenerated == 'SELESAI' ? 'text-bg-success' : (laporan?.statusGenerated == 'SEDANG DIKERJAKAN' ? 'text-bg-warning' : 'text-bg-danger')}}">{{laporan?.statusGenerated}}</div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 col-sm-12 mb-3">
                    <div class="font-14 font-weight-bold">Dibuat oleh</div>
                    <div>{{laporan?.nmKaryawan}}</div>
                </div>
                <div class="col-md-4 col-sm-12 mb-3">
                    <div class="font-14 font-weight-bold">Unit Pelapor</div>
                    <div>{{laporan?.nmUnit}}</div>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col">
                    <div class="font-14 font-weight-bold">Uraian Laporan Utama</div>
                    <div>{{laporan?.uraian}}</div>
                </div>
            </div>

            <div class="d-flex mb-4 align-items-center">
                <div class="flex-grow-1 font-20">
                    <wui-icon icon="domain" class="me-2"></wui-icon>
                    <span class="font-weight-500">Laporan Terkait Unit</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 mb-3">
                    <div class="font-14 font-weight-bold">Tanggal</div>
                    <div>{{laporanUnit?.insertedAt.date | date:'dd MMMM yyyy HH:mm'}}</div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="font-14 font-weight-bold">Status Laporan Unit</div>
                    <div class="status {{laporanUnit?.status == 'SELESAI' ? 'text-bg-success' : (laporanUnit?.status == 'SEDANG DIKERJAKAN' ? 'text-bg-warning' : 'text-bg-danger')}}">{{laporanUnit?.status}}</div>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col">
                    <div class="font-14 font-weight-bold">Uraian Tugas / Keterangan Untuk Unit</div>
                    <div>{{laporanUnit.uraian ?? 'Tidak ada uraian'}}</div>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col">
                    <div class="font-14 font-weight-bold">Tindak Lanjut Unit</div>
                    <div>{{laporanUnit.tindakLanjut ?? 'Belum ada tindak lanjut'}}</div>
                </div>
            </div>
        </div>
    </div>
</wui-page>