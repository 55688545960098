import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PenggunaComponent } from './pages/pengguna/pengguna/pengguna.component';
import { PenggunaFormComponent } from './pages/pengguna/pengguna-form/pengguna-form.component';
import { UnitFormComponent } from './pages/unit/unit-form/unit-form.component';
import { UnitComponent } from './pages/unit/unit/unit.component';
import { JabatanComponent } from './pages/jabatan/jabatan/jabatan.component';
import { JabatanFormComponent } from './pages/jabatan/jabatan-form/jabatan-form.component';
import { LoginComponent } from './pages/login/login.component';
import { mustLoggedInAdmin, mustLoginFirst } from './guards/mustLoginFirst';
import { KerjasamaFormComponent } from './pages/kerjasama/kerjasama-form/kerjasama-form.component';
import { KerjasamaComponent } from './pages/kerjasama/kerjasama/kerjasama.component';
import { LaporanComponent } from './pages/laporan/laporan/laporan.component';
import { LaporanFormComponent } from './pages/laporan/laporan-form/laporan-form.component';
import { LaporanTerkaitComponent } from './pages/laporan/laporan-terkait/laporan-terkait.component';
import { LaporanTerkaitFormComponent } from './pages/laporan/laporan-terkait-form/laporan-terkait-form.component';
import { LaporanDetailComponent } from './pages/laporan/laporan-detail/laporan-detail.component';
import { LaporanTerkaitDetailComponent } from './pages/laporan/laporan-terkait-detail/laporan-terkait-detail.component';
import { SettingPasswordComponent } from './pages/setting/setting-password/setting-password.component';

const routes: Routes = [{
  path: 'login', component: LoginComponent
}, {
  path: 'home', canActivate: [mustLoginFirst], component: HomeComponent
}, {
  path: 'pengguna', component: PenggunaComponent, canActivate: [mustLoggedInAdmin(['ADMINISTRATOR'])], children: [{
    path: 'baru', component: PenggunaFormComponent
  }, {
    path: 'edit/:idPengguna', component: PenggunaFormComponent
  }]
}, {
  path: 'unit', component: UnitComponent, canActivate: [mustLoggedInAdmin(['ADMINISTRATOR'])], children: [{
    path: 'baru', component: UnitFormComponent
  }, {
    path: 'edit/:idUnit', component: UnitFormComponent
  }]
}, {
  path: 'jabatan', component: JabatanComponent, canActivate: [mustLoggedInAdmin(['ADMINISTRATOR'])], children: [{
    path: 'baru', component: JabatanFormComponent
  }, {
    path: 'edit/:idJabatan', component: JabatanFormComponent
  }]
}, {
  path: 'laporan', component: LaporanComponent, canActivate: [mustLoginFirst], children: [{
    path: 'baru', component: LaporanFormComponent
  }, {
    path: 'edit/:idLaporan', component: LaporanFormComponent
  }, {
    path: ':idLaporan', component: LaporanDetailComponent
  }]
}, {
  path: 'laporanTerkait', component: LaporanTerkaitComponent, canActivate: [mustLoginFirst], children: [{
    path: 'edit/:idLaporanUnit', component: LaporanTerkaitFormComponent
  }, {
    path: ':idLaporanUnit', component: LaporanTerkaitDetailComponent
  }]
}, {
  path: 'kerjasama', component: KerjasamaComponent, canActivate: [mustLoginFirst], children: [{
    path: 'baru', component: KerjasamaFormComponent
  }, {
    path: 'edit/:idKerjasama', component: KerjasamaFormComponent
  }]
}, {
  path: 'profil', component: SettingPasswordComponent, canActivate: [mustLoginFirst]
}, {
  path: '', redirectTo: 'home', pathMatch: 'full'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
