import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ModalComponent, WuiService } from '@wajek/wui';
import { Subject } from 'rxjs';
import { UnitCariComponent } from '../../unit/unit-cari/unit-cari.component';
import { LaporanUnitService } from 'src/app/services/laporan-unit.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-laporan-item-form',
  templateUrl: './laporan-item-form.component.html',
  styleUrl: './laporan-item-form.component.scss'
})
export class LaporanItemFormComponent {

  @ViewChild('modal', {static: true}) modal?: ModalComponent;
  @ViewChild('formCariUnit', {static: true}) formCariUnit?: UnitCariComponent;
  
  aksi = 'insert';
  idLaporanUnit = 0;
  submitted = false;

  $result: Subject<any> = new Subject();

  formItem = new UntypedFormGroup({
    idUnit: new UntypedFormControl(null, Validators.required),
    uraian: new UntypedFormControl(null),
    tindakLanjut: new UntypedFormControl(null),
    status: new UntypedFormControl(null)
  });

  unit: any;
  get labelUnit() {
    return this.unit?.nmUnit ?? null;
  }

  constructor(
    private wuiService: WuiService,
    private laporanUnitService: LaporanUnitService,
    private activatedRoute: ActivatedRoute
  ) { }

  async pilihUnit() {
    let unit: any = await this.formCariUnit?.open();
    if(unit != 'close' && unit != null) {
      this.formItem.controls['idUnit'].setValue(unit.idUnit);
      this.unit = unit;
    }
    return this.unit;
  }

  close() {
    this.$result.next('close');
  }

  insert() {
    return new Promise(async (resolve) => {
      this.aksi = 'insert';
      this.idLaporanUnit = 0;
      this.formItem.controls['idUnit'].setValue(null);
      this.formItem.controls['uraian'].setValue(null);
      this.formItem.controls['tindakLanjut'].setValue(null);
      this.formItem.controls['status'].setValue('BELUM DIKERJAKAN');
      this.unit = null;
      this.modal?.open();

      let sub = this.$result.subscribe(result => {
        resolve(result);
        this.modal?.close();
        sub.unsubscribe();
      });
    });
  }

  edit(item: any) {
    return new Promise(async (resolve) => {
      this.aksi = 'edit';
      this.idLaporanUnit = item.idLaporanUnit;
      this.formItem.controls['idUnit'].setValue(item?.idUnit);
      this.formItem.controls['uraian'].setValue(item?.uraian);
      this.formItem.controls['tindakLanjut'].setValue(item?.tindakLanjut);
      this.formItem.controls['status'].setValue(item?.status);

      this.unit = {
        idUnit: item?.idUnit,
        kodeUnit: item?.kodeUnit,
        nmUnit: item?.nmUnit
      };

      this.submitted = false;
      this.modal?.open();

      let sub = this.$result.subscribe(result => {
        resolve(result);
        this.modal?.close();
        sub.unsubscribe();
      });
    });
  }

  async submit() {
    this.submitted = true;
    if(this.formItem.invalid) {
      this.wuiService.dialog({
        title: 'Error',
        message: 'Periksa kembali isian anda',
        buttons: ['OK']
      });
      return;
    };

    try {
      this.wuiService.openLoading();
      let data: any = {
        idLaporan: this.activatedRoute.snapshot.params['idLaporan'],
        idUnit: this.formItem.controls['idUnit'].value,
        uraian: this.formItem.controls['uraian'].value
      };
      if(this.aksi == 'insert') {
        let res: any = await this.laporanUnitService.insert(data);
        data.idLaporanUnit = res.idLaporanUnit;
        data.status = 'BELUM DIKERJAKAN';

        this.wuiService.snackbar({
          label: 'Laporan Unit berhasil ditambahkan'
        });
      } else if(this.aksi == 'edit') {
        data.status = this.formItem.controls['status'].value;
        if(data.status == 'SELESAI') {
          data.tindakLanjut = this.formItem.controls['tindakLanjut'].value;
        }
        await this.laporanUnitService.update(this.idLaporanUnit, data);
        data.idLaporanUnit = this.idLaporanUnit;

        this.wuiService.snackbar({
          label: 'Laporan Unit berhasil diupdate'
        });
      }
      data.nmUnit = this.unit?.nmUnit;
      data.kodeUnit = this.unit?.kodeUnit;
      this.$result.next(data);
      this.wuiService.closeLoading();
    } catch(e: any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi SIM RS',
        buttons: ['Ok']
      });
    }
  }

}
