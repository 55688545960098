import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { WuiService, MessageService } from '@wajek/wui';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-setting-password',
  templateUrl: './setting-password.component.html',
  styleUrl: './setting-password.component.scss'
})
export class SettingPasswordComponent implements OnInit{

  penggunaAktif: any;

  currentPassInput = false;
  newPassInput = false;
  confirmPassInput = false;

  formUbahPassword = new UntypedFormGroup({
    currentPassword: new UntypedFormControl(null, Validators.required),
    newPassword: new UntypedFormControl(null, Validators.required),
    confirm: new UntypedFormControl(null, Validators.required)
  });

  private unsub: Subject<any> = new Subject();

  constructor(
    private authService: AuthService,
    private wuiService: WuiService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  reset() {
    this.formUbahPassword.controls['currentPassword'].setValue(null);
    this.formUbahPassword.controls['newPassword'].setValue(null);
    this.formUbahPassword.controls['confirm'].setValue(null);
  }

  toggleFieldTextType(input: any) {
    if(input == 'currentPass') {
      this.currentPassInput = !this.currentPassInput;
    } else if(input == 'newPass') {
      this.newPassInput = !this.newPassInput;
    } else {
      this.confirmPassInput = !this.confirmPassInput;
    }
  }

  async submit() {
    if(this.formUbahPassword.controls['newPassword'].value != null) {
      if(this.formUbahPassword.controls['newPassword'].value != this.formUbahPassword.controls['confirm'].value) {
        this.wuiService.dialog({
          title: 'Error',
          message: 'Konfirmasi password tidak cocok',
          buttons: ['OK']
        });
        return;
      }
    }

    try {
      this.wuiService.openLoading();
      let data = this.formUbahPassword.value;
      await this.authService.updatePassword(data);
      this.messageService.set('settingProfil:afterChangePassword', {});
      this.wuiService.closeLoading();
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute
      });
    } catch(e: any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['Ok']
      });
    }
  }

  ngOnInit(): void {
    this.authService.penggunaAktif.subscribe(penggunaAktif => {
      this.penggunaAktif = penggunaAktif;
    });  

    this.messageService.get('settingProfil:afterChangePassword').pipe(takeUntil(this.unsub)).subscribe(res => {
      this.wuiService.snackbar({
        label: 'Password berhasil diubah'
      });
      this.reset();
    });
  }

}