import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalComponent, WuiService } from '@wajek/wui';
import { Subject } from 'rxjs';
import { JabatanService } from 'src/app/services/jabatan.service';
import { takeUntil, debounceTime } from 'rxjs';
import { JabatanFormComponent } from '../jabatan-form/jabatan-form.component';

@Component({
  selector: 'app-jabatan-cari',
  templateUrl: './jabatan-cari.component.html',
  styleUrl: './jabatan-cari.component.scss'
})
export class JabatanCariComponent {

  @ViewChild('modal', { static: true }) modal?: ModalComponent;
  @ViewChild('searchInput', { static: false }) searchInput?: ElementRef;
  @ViewChild('formJabatan', { static: true }) formJabatan?: JabatanFormComponent;

  isLoading = false;
  dataJabatan: Array<any> = [];

  page = 1;
  limit = 30;
  count = 0;

  q = '';
  search: Subject<any> = new Subject();

  private result: Subject<any> = new Subject();
  private unsub: Subject<any> = new Subject();

  constructor(
    private wuiService: WuiService,
    private jabatanService: JabatanService
  ) { }

  async tambahJabatan() {
    let jabatan = await this.formJabatan?.insert();
    if(jabatan != 'close' && jabatan != null) {
      this.result.next(jabatan);
    }
  }

  select(jabatan: any) {
    this.result.next(jabatan);
  }

  first() {
    this.page = 1;
    this.refresh();
  }

  prev() {
    if(this.page > 1) {
      this.page--;
      this.refresh();
    }
  }

  next() {
    if(this.page < this.pageCount) {
      this.page++;
      this.refresh();
    }
  }

  last() {
    this.page = this.pageCount;
    this.refresh();
  }

  get offset() {
    return (this.page - 1) * this.limit;
  }

  get pageCount() {
    return Math.ceil(this.count / this.limit);
  }

  async refresh() {
    try {
      this.dataJabatan = [];
      this.isLoading = true;
      let params: any = {
        offset: this.offset,
        limit: this.limit,
        search: this.q
      };
      let res: any = await this.jabatanService.result(params);
      this.dataJabatan = res.data;
      this.count = res.count;
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = true;
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }

  open(): Promise<any> {
    return new Promise((resolve) => {
      this.modal?.open();

      setTimeout(() => {
        this.searchInput?.nativeElement.focus();
      }, 100);

      this.refresh();

      let sub = this.result.subscribe(result => {
        resolve(result);
        this.modal?.close();
        sub.unsubscribe();
      })
    })
  }

  close() {
    this.result.next('close');
  }

  ngOnInit(): void {
    this.search.pipe(takeUntil(this.unsub), debounceTime(500)).subscribe(e => {
      this.q = e.target.value;
      this.page = 1;
      this.refresh();
    });
  }

  ngOnDestroy() {
    this.unsub.next(null);
  }

}
