import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, filter, tap, map } from "rxjs";
import { AuthService } from "../services/auth.service";
import { inject } from "@angular/core";

export const mustLoginFirst : CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    let authService : AuthService = inject(AuthService);
    let router: Router = inject(Router);
    return authService.isLoggedIn.pipe(filter((isLoggedIn) => isLoggedIn !== null), tap((isLoggedIn) => {
        if(isLoggedIn === false) {
            router.navigate(['/login']);
        }
    }));
}

export const mustLoggedInAdmin : any = (allowedAkses: Array<string> = ['ADMINISTRATOR']) => {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> => {
        let authService: AuthService = inject(AuthService);
        let router: Router = inject(Router);
        return authService.isLoggedIn.pipe(filter((isLoggedIn) => isLoggedIn != null), tap((isLoggedIn) => {
            if(isLoggedIn === true) {
                if(allowedAkses.indexOf(authService.penggunaAktif?.getValue().akses) == -1) {
                    router.navigate(['/home']);
                }
            } else {
                router.navigate(['/login']);
            }
            return;
        }), map((isLoggedIn) => { 
            if(isLoggedIn == true) {
                if(allowedAkses.indexOf(authService.penggunaAktif?.getValue().akses) == -1) {
                    return false;
                }
            }
            return isLoggedIn; 
        }));
    }
}