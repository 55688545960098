import { Component, ViewChild, OnInit, ElementRef, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent, WuiService, MessageService } from '@wajek/wui';
import { Subject } from 'rxjs';
import { PenggunaService } from 'src/app/services/pengguna.service';
import { JabatanCariComponent } from '../../jabatan/jabatan-cari/jabatan-cari.component';
import { UnitCariComponent } from '../../unit/unit-cari/unit-cari.component';

@Component({
  selector: 'app-pengguna-form',
  templateUrl: './pengguna-form.component.html',
  styleUrl: './pengguna-form.component.scss'
})
export class PenggunaFormComponent implements OnInit{

  @ViewChild('modal', {static: true}) modal?: ModalComponent;
  @Input('embed') embed?: boolean = false;
  @ViewChild('formCariJabatan', {static: true}) formCariJabatan?: JabatanCariComponent;
  @ViewChild('formCariUnit', {static: true}) formCariUnit?: UnitCariComponent;
  @ViewChild('inputFocus', {static: false}) inputFocus?: ElementRef;

  aksi = 'insert';
  idPengguna = 0;
  formPengguna = new UntypedFormGroup({
    kodeKaryawan: new UntypedFormControl(null),
    nmKaryawan: new UntypedFormControl(null, Validators.required),
    kodeUser: new UntypedFormControl(null, Validators.required),
    idJabatan: new UntypedFormControl(null),
    akses: new UntypedFormControl(null, Validators.required),
    password: new UntypedFormControl(null),
    idUnit: new UntypedFormControl(null),
    isAktif: new UntypedFormControl(1),
    confirm: new UntypedFormControl(null)
  });

  jabatan: any;
  get labelJabatan() {
    return this.jabatan?.nmJabatan ?? null;
  }

  unit: any = null;
  get labelUnit() {
    return this.unit?.nmUnit ?? null;
  }

  private $result: Subject<any> = new Subject();

  passInputType: any = false;
  confirmPassInputType: any = false;

  constructor(
    private wuiService: WuiService,
    private penggunaService: PenggunaService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  toggleFieldTextType(input: any) {
    if(input == 'password') {
      this.passInputType = !this.passInputType;
    } else {
      this.confirmPassInputType = !this.confirmPassInputType;
    }
  }

  close() {
    this.$result.next('close');
  }

  aksesChange(e: any) {
    if(e.target.value == 'ADMINISTRATOR') {
      this.formPengguna.controls['idUnit'].setValue(null);
      this.formPengguna.controls['idJabatan'].setValue(null);
      this.unit = null;
      this.jabatan = null;
    }
  }

  async pilihJabatan() {
    let jabatan: any = await this.formCariJabatan?.open();
    if(jabatan != 'close' && jabatan != null) {
      this.formPengguna.controls['idJabatan'].setValue(jabatan.idJabatan);
      this.jabatan = jabatan;
    }
  }

  async pilihUnit() {
    let unit: any = await this.formCariUnit?.open();
    if(unit != 'close' && unit != null) {
      this.formPengguna.controls['idUnit'].setValue(unit.idUnit);
      this.unit = unit;
    }
  }

  insert() {
    return new Promise((resolve) => {
      this.aksi = 'insert';
      this.formPengguna.controls['kodeKaryawan'].setValue(null);
      this.formPengguna.controls['nmKaryawan'].setValue(null);
      this.formPengguna.controls['kodeUser'].setValue(null);
      this.formPengguna.controls['password'].setValue(null);
      this.formPengguna.controls['akses'].setValue(null);
      this.formPengguna.controls['idJabatan'].setValue(null);
      this.formPengguna.controls['idUnit'].setValue(null);
      this.formPengguna.controls['isAktif'].setValue(1);
      this.formPengguna.controls['confirm'].setValue(null);

      this.modal?.open();
      setTimeout(() => {
        this.inputFocus?.nativeElement.focus();
      }, 100);

      let sub = this.$result.subscribe(result => {
        resolve(result);
        this.modal?.close();
        sub.unsubscribe();
      });
    });
  }

  edit(idPengguna: any) {
    return new Promise(async (resolve) => {
      try {
        this.wuiService.openLoading();
        this.aksi = 'edit';
        this.idPengguna = idPengguna;

        let pengguna: any = await this.penggunaService.row(this.idPengguna);
        this.formPengguna.controls['kodeKaryawan'].setValue(pengguna?.kodeKaryawan);
        this.formPengguna.controls['nmKaryawan'].setValue(pengguna?.nmKaryawan);
        this.formPengguna.controls['kodeUser'].setValue(pengguna?.kodeUser);
        this.formPengguna.controls['akses'].setValue(pengguna?.akses);
        this.formPengguna.controls['idJabatan'].setValue(pengguna?.idJabatan);
        this.formPengguna.controls['idUnit'].setValue(pengguna?.idUnit);
        this.formPengguna.controls['isAktif'].setValue(pengguna?.isAktif);
        this.formPengguna.controls['password'].setValue(null);
        this.formPengguna.controls['confirm'].setValue(null);
        
        if(pengguna?.idJabatan != null) {
          this.jabatan = {
            idJabatan: pengguna?.idJabatan,
            nmJabatan: pengguna?.nmJabatan
          };
        }

        if(pengguna?.idUnit != null) {
          this.unit = {
            idUnit: pengguna?.idUnit,
            nmUnit: pengguna?.nmUnit,
            kodeUnit: pengguna?.kodeUnit
          };
        }

        this.wuiService.closeLoading();

        this.modal?.open();
        setTimeout(() => {
          this.inputFocus?.nativeElement.focus();
        }, 100);

        let sub = this.$result.subscribe(result => {
          resolve(result);
          this.modal?.close();
          sub.unsubscribe();
        });
      } catch (e: any) {
        this.wuiService.closeLoading();
        this.wuiService.dialog({
          title: 'Error',
          message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
          buttons: ['OK']
        });
      }
    });
  }

  async submit() {
    if(this.formPengguna.invalid) {
      this.wuiService.dialog({
        title: 'Error',
        message: 'Periksa kembali isian anda',
        buttons: ['OK']
      });
      return;
    };

    if(this.formPengguna.controls['akses'].value !== 'ADMINISTRATOR' && (this.formPengguna.controls['idUnit'].value == null)) {
      this.wuiService.dialog({
        title: 'Error',
        message: 'Periksa kembali isian Unit',
        buttons: ['OK']
      });
      return;
    }

    if(this.aksi == 'insert' || (this.aksi == 'edit' && this.formPengguna.controls['password'].value != null)) {
      if(this.formPengguna.controls['password'].value != this.formPengguna.controls['confirm'].value) {
        this.wuiService.dialog({
           title: 'Error',
           message: 'Periksa kembali konfirmasi password',
           buttons: ['OK']
        });
        return;
      }
    }

    try {
      this.wuiService.openLoading();
      let data: any = {
        kodeKaryawan: this.formPengguna.controls['kodeKaryawan'].value,
        nmKaryawan: this.formPengguna.controls['nmKaryawan'].value,
        idJabatan: this.formPengguna.controls['idJabatan'].value,
        kodeUser: this.formPengguna.controls['kodeUser'].value,
        akses: this.formPengguna.controls['akses'].value,
        idUnit: this.formPengguna.controls['idUnit'].value,
        isAktif: this.formPengguna.controls['isAktif'].value
      };

      if(this.aksi == 'insert' || (this.aksi == 'edit' && this.formPengguna.controls['password'].value != null)) {
        data.password = this.formPengguna.controls['password'].value;
      }
      if(this.aksi == 'insert') {
        let res: any = await this.penggunaService.insert(data);
        data.idPengguna = res.idPengguna;
      } else {
        await this.penggunaService.update(this.idPengguna,data);
        data.idPengguna = this.idPengguna;
      }
      this.wuiService.closeLoading();
      this.$result.next(data);
    } catch(e: any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }

  async ngOnInit() {
    if(this.embed) return;

    try {
      let idPengguna = this.activatedRoute.snapshot.params['idPengguna'] ?? null;
      if(idPengguna !== null){
        let result = await this.edit(idPengguna);
        if(result !== 'close' && result !== null) {
          this.messageService.set('pengguna:afterUpdate', result);
        }
      } else {
        let result = await this.insert();
        if(result !== 'close' && result !== null) {
          this.messageService.set('pengguna:afterInsert', result);
        }
      }
      this.router.navigate([idPengguna !== null ? '../../' : '../'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge'
      }); 
    } catch (e: any) {
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['Ok']
      });
    }
  }

}
