import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { LaporanService } from 'src/app/services/laporan.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { WuiService, MessageService } from '@wajek/wui';
import { takeUntil, debounceTime } from 'rxjs';
import { LaporanFilterComponent } from '../laporan-filter/laporan-filter.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-laporan',
  templateUrl: './laporan.component.html',
  styleUrl: './laporan.component.scss'
})
export class LaporanComponent implements OnInit, OnDestroy{

  @ViewChild('searchInput', { static: false }) searchInput?: ElementRef;
  @ViewChild('formLaporanFilter', {static: true}) formLaporanFilter?: LaporanFilterComponent;
  
  lastPage = false;
  isLoading = false;

  penggunaAktif: any;
  dataLaporan: Array<any> = [];
  selectedIndex = -1;

  count = 0;
  limit = 30;

  q = '';
  search: Subject<any> = new Subject();
  searchMode = false;

  page = 1;

  get offset() {
    return (this.page - 1) * this.limit;
  }

  get pageCount() {
    return Math.ceil(this.count / this.limit);
  }

  awal: any;
  akhir: any;

  idUnit: any;
  jenis: any;
  status: any;

  private unsub: Subject<any> = new Subject();

  constructor(
    private laporanService: LaporanService,
    private messageService: MessageService,
    private wuiService: WuiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  async filter() {
    let res: any = await this.formLaporanFilter?.open({
      awal: this.awal,
      akhir: this.akhir,
      idUnit: this.idUnit,
      jenis: this.jenis,
      status: this.status
    });
    if(res != null && res != 'close') {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          awal: res.awal,
          akhir: res.akhir,
          idUnit: res.idUnit ?? null,
          jenis: res.jenis == 'SEMUA JENIS' ? null : res.jenis,
          status: res.status == 'SEMUA STATUS' ? null : res.status
        }
      });
    }
  }

  get isFiltered() {
    return this.awal != null || this.akhir != null || this.idUnit != null || this.jenis != null || this.status != null;
  }

  toggleSearch() {
    this.searchMode = !this.searchMode;

    setTimeout(() => {
      this.searchInput?.nativeElement.focus();
    }, 0);

    if (!this.searchMode) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          page: 1,
          search: null
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  first() {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams:  {
        page: 1
      },
      queryParamsHandling: 'merge'
    });
  }

  prev() {
    if(this.page > 1) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams:  {
          page: this.page - 1
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  next() {
    if(this.page < this.pageCount) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams:  {
          page: this.page +  1
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  last() {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams:  {
        page: this.pageCount
      },
      queryParamsHandling: 'merge'
    });
  }

  async hapus() {
    let confirmResult = await this.wuiService.dialog({
      title: 'Konfirmasi',
      message: 'Anda yakin menghapus laporan ?',
      buttons: ['Batal', 'Hapus']
    });
    if(confirmResult == 1) {
      try {
        this.wuiService.openLoading();
        await this.laporanService.delete(this.dataLaporan[this.selectedIndex].idLaporan);
        this.wuiService.closeLoading();
        this.dataLaporan.splice(this.selectedIndex, 1);
        this.wuiService.snackbar({
          label: 'Data laporan berhasil dihapus'
        });
      } catch(e :any) {
        this.wuiService.closeLoading();
        this.wuiService.dialog({
          title: 'Error',
          message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
          buttons: ['Ok']
        });
      }
    }
  }

  async refresh() {
    try {
      this.dataLaporan = [];
      this.isLoading = true;
      let params: any = {
        offset: this.offset,
        limit: this.limit
      };
      if(this.q != null) { params.search = this.q; }
      if (this.awal != null) { params.awal = this.awal };
      if (this.akhir != null) { params.akhir = this.akhir };
      if(this.idUnit != null) params.idUnit = this.idUnit;
      if(this.jenis != null) params.jenis = this.jenis;
      if(this.status != null) params.status = this.status;
      let res: any = await this.laporanService.result(params);
      this.dataLaporan = res.data;
      this.count = res.count;
      this.awal = params.awal ?? res.awal;
      this.akhir = params.akhir ?? res.akhir;
      this.isLoading = false;
    } catch(e: any) {
      this.isLoading = false;
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['Ok']
      });
    }
  }

  ngOnInit(): void {
    this.authService.penggunaAktif.subscribe(penggunaAktif => {
      this.penggunaAktif = penggunaAktif;
    });  
    
    this.messageService.get('laporan:afterInsert').subscribe(data => {
      this.dataLaporan.splice(0, 0, data);
    });

    this.messageService.get('laporan:afterUpdate').subscribe(data => {
      let index = this.dataLaporan.findIndex((laporan: any) => laporan.idLaporan == data.idLaporan);
      if(index > -1) {
        this.dataLaporan[index].jenisLaporan = data.jenisLaporan;
        this.dataLaporan[index].uraian = data.uraian;
        this.dataLaporan[index].status = data.statusGenerated;
        this.dataLaporan[index].tanggal = data.tanggal;
      }
    });

    this.search.pipe(takeUntil(this.unsub), debounceTime(500)).subscribe(e => {
      if(this.searchMode) {
        this.router.navigate(['./'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            page: 1,
            search: e.target.value,
          },
          queryParamsHandling: 'merge'
        });
      } else { 
        this.q = '';
        return; 
      }
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.unsub)).subscribe(queryParams => {
      this.page = queryParams['page'] != null ? parseInt(queryParams['page']) : 1;
      this.q = queryParams['search'] ?? null;
      this.awal = queryParams['awal'] ?? null;
      this.akhir = queryParams['akhir'] ?? null;
      this.idUnit = queryParams['idUnit'] ?? null;
      this.jenis = queryParams['jenis'] ?? null;
      this.status = queryParams['status'] ?? null;
      this.searchMode = this.q != null;
      this.refresh();
    });
  }

  ngOnDestroy() {
    this.unsub.next(null);
  }

}
