<form [formGroup]="formJabatan" (submit)="submit()">
    <wui-modal #modal>
        <div class="wui-modal-header">
            <div class="d-flex justify-content-between align-items-center">
                <div>{{aksi == 'insert' ? 'Jabatan Baru' : 'Edit Jabatan'}}</div>
                <button class="wui-button wui-button-smooth wui-button-icon" type="button" (click)="close()">
                    <wui-icon icon="close"></wui-icon>
                </button>
            </div>
        </div>
    
        <div class="wui-modal-content pt-2">
            <wui-form-field class="wui-form-field-boxed">
                <label for="nmJabatan">Nama Jabatan</label>
                <input id="nmJabatan" type="text" wuiInput formControlName="nmJabatan" #inputFocus>
            </wui-form-field>

            <wui-form-field class="wui-form-field-boxed">
                <label for="akses">Akses</label>
                <select name="akses" id="aksesJabatan" wuiInput formControlName="akses">
                    <option value="PELAKSANA">Pelaksana</option>
                    <option value="SPV">SPV / Karu</option>
                    <option value="MANAGER">Manager / QC</option>
                    <option value="DIREKTUR">Direktur</option>
                </select>
            </wui-form-field>

            <wui-form-field class="wui-form-field-boxed">
                <label for="idUnit">Unit</label>
                <input id="idUnitJabatan" type="text" wuiInput readonly [ngModel]="labelUnit" [ngModelOptions]="{standalone: true}">
                <div class="wui-form-field-suffix">
                    <button class="wui-button wui-button-icon wui-button-smooth" type="button" (click)="pilihUnit()">
                        <wui-icon icon="chevron-down"></wui-icon>
                    </button>
                </div>
            </wui-form-field>
        </div>
    
        <div class="wui-modal-footer">
            <div class="d-flex justify-content-between align-items-center">
                <button class="wui-button" type="button" (click)="close()">Batal</button>
                <button class="wui-button wui-button-primary" type="submit">Simpan</button>
            </div>
        </div>
    </wui-modal>
</form>

<app-unit-cari #formCariUnit></app-unit-cari>