<wui-page>
    <wui-app-bar>
        <div class="wui-app-bar-leading">
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" wuiToggleDrawer>
                <wui-icon icon="menu"></wui-icon>
            </button>
        </div>
        <div class="wui-app-bar-title"></div>
        <div class="wui-app-bar-trailing"></div>
    </wui-app-bar>

    <div class="wui-page-inner">
        <div class="wui-page-container">
            <div class="mb-5 d-flex align-items-center">
                <div class="flex-grow-1 font-28 font-weight-500">Dashboard</div>
            </div>

            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                <div class="col">
                    <div class="card radius-10 border-start border-0 border-3 border-success">
                        <div class="card-body" routerLink="/laporan">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary font-18">Total Laporan</p>
                                    <h4 class="my-1 text-success">{{laporanAll}}</h4>
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-success text-white ms-auto">
                                    <wui-icon icon="file-chart"></wui-icon>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10 border-start border-0 border-3 border-info">
                        <div class="card-body" routerLink="/laporan" [queryParams]="{jenis: 'INFO'}">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary font-18">Info</p>
                                    <h4 class="my-1 text-info">{{laporanInfo}}</h4>
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-info text-white ms-auto">
                                    <wui-icon icon="comment-quote"></wui-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10 border-start border-0 border-3 border-danger">
                        <div class="card-body" routerLink="/laporan" [queryParams]="{jenis: 'TANTANGAN'}">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary font-18">Tantangan</p>
                                    <h4 class="my-1 text-danger">{{laporanTantangan}}</h4>
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-danger text-white ms-auto">
                                    <wui-icon icon="comment-alert"></wui-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10 border-start border-0 border-3 border-warning">
                        <div class="card-body" routerLink="/laporan" [queryParams]="{jenis: 'HUMAN ERROR'}">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary font-18">Human Error</p>
                                    <h4 class="my-1 text-warning">{{laporanHumanError}}</h4>
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-warning text-white ms-auto">
                                    <wui-icon icon="comment-account"></wui-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            <!-- <div class="row">
                <div class="col-md-3">
                    <div class="chart-box">
                        <div class="chart-box-label">
                            <p>Total Laporan</p>
                            <h1>3994</h1>
                        </div>
                        <div class="chart-box-icon">
                            <wui-icon icon="comment-alert"></wui-icon>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="chart-box">
                        <div class="chart-box-label">
                            <p>Total Laporan</p>
                            <h1>3994</h1>
                        </div>
                        <div class="chart-box-icon">
                            <wui-icon icon="comment-alert"></wui-icon>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="chart-box">
                        <div class="chart-box-label">
                            <p>Total Laporan</p>
                            <h1>3994</h1>
                        </div>
                        <div class="chart-box-icon">
                            <wui-icon icon="comment-alert"></wui-icon>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="chart-box">
                        <div class="chart-box-label">
                            <p>Total Laporan</p>
                            <h1>3994</h1>
                        </div>
                        <div class="chart-box-icon">
                            <wui-icon icon="comment-alert"></wui-icon>
                        </div>
                    </div>
                </div>
            </div> -->


            <!-- <ngx-charts-line-chart
                [view]="view"
                [scheme]="colorScheme"
                [results]="data"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                
                [autoScale]="autoScale">
            </ngx-charts-line-chart> -->
        </div>
    </div>
</wui-page>