import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent, WuiService, MessageService } from '@wajek/wui';
import { Subject } from 'rxjs';
import { JabatanService } from 'src/app/services/jabatan.service';
import { UnitCariComponent } from '../../unit/unit-cari/unit-cari.component';

@Component({
  selector: 'app-jabatan-form',
  templateUrl: './jabatan-form.component.html',
  styleUrl: './jabatan-form.component.scss'
})
export class JabatanFormComponent {

  @ViewChild('formCariUnit', { static: true }) formCariUnit?: UnitCariComponent;
  @ViewChild('modal', {static: true}) modal?: ModalComponent;
  @ViewChild('inputFocus', {static: false}) inputFocus?: ElementRef;
  @Input('embed') embed?: boolean = false;

  aksi: string = 'insert';
  idJabatan: number | null = 0;

  formJabatan = new UntypedFormGroup({
    nmJabatan: new UntypedFormControl(null, Validators.required),
    idUnit: new UntypedFormControl(null, Validators.required),
    akses: new UntypedFormControl(null, Validators.required)
  });
  
  private $result: Subject<any> = new Subject();

  unit: any = null;
  get labelUnit() {
    return this.unit?.nmUnit ?? null;
  }

  constructor(
    private jabatanService: JabatanService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private wuiService: WuiService,
    private messageService: MessageService
  ) { }

  close() {
    this.$result.next('close');
  }

  async pilihUnit() {
    let unit: any = await this.formCariUnit?.open();
    if(unit != 'close' && unit != null) {
      this.formJabatan.controls['idUnit'].setValue(unit.idUnit);
      this.unit = unit;
    }
  }

  insert() {
    return new Promise(async (resolve) => {
      this.aksi = 'insert';
      this.formJabatan.controls['nmJabatan'].setValue(null);

      this.modal?.open();
      setTimeout(() => {
        this.inputFocus?.nativeElement.focus();
      }, 100);

      let sub = this.$result.subscribe(result => {
        resolve(result);
        this.modal?.close();
        sub.unsubscribe();
      });
    });
  }

  edit(idJabatan: any) {
    return new Promise(async (resolve) => {
      try {
        this.wuiService.openLoading();
        this.aksi = 'edit';
        this.idJabatan = idJabatan;

        let jabatan: any = await this.jabatanService.row(this.idJabatan);
        this.formJabatan.controls['nmJabatan'].setValue(jabatan?.nmJabatan);
        this.formJabatan.controls['akses'].setValue(jabatan?.akses);
        this.formJabatan.controls['idUnit'].setValue(jabatan?.idUnit);
        this.unit = {
          idUnit: jabatan?.idUnit,
          nmUnit: jabatan?.nmUnit
        };
        this.wuiService.closeLoading();

        this.modal?.open();
        setTimeout(() => {
          this.inputFocus?.nativeElement.focus();
        }, 100);

        let sub = this.$result.subscribe(result => {
          resolve(result);
          this.modal?.close();
          sub.unsubscribe();
        })
      } catch (e: any) {
        this.wuiService.closeLoading();
        this.wuiService.dialog({
          title: 'Error',
          message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
          buttons: ['OK']
        });
      }
    })
  }

  async submit() {
    if (this.formJabatan.invalid) {
      this.wuiService.dialog({
        title: 'Error',
        message: 'Periksa kembali isian anda',
        buttons: ['OK']
      });
      return;
    };

    try {
      this.wuiService.openLoading();
      let data: any = this.formJabatan.value;
      if(this.aksi == 'insert') {
        let res: any = await this.jabatanService.insert(data);
        data.idJabatan = res.idJabatan;
        data.nmUnit = this.unit?.nmUnit;
        this.messageService.set('jabatan:afterInsert', data);
        this.wuiService.snackbar({
          label: 'Jabatan berhasil ditambahkan'
        });
      } else {
        await this.jabatanService.update(this.idJabatan, data);
        data.idJabatan = this.idJabatan;
        data.nmUnit = this.unit?.nmUnit;
        data.akses = data.akses;
        this.messageService.set('jabatan:afterUpdate', data);
        this.wuiService.snackbar({
          label: 'Jabatan berhasil diupdate'
        });
      }
      this.wuiService.closeLoading();
      this.$result.next(data);
    } catch(e: any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi orang it',
        buttons: ['Ok']
      });
    }
  }
  
  async ngOnInit() {
    if(this.embed) return;

    try {
      let idJabatan = this.activatedRoute.snapshot.params['idJabatan'] ?? null;
      if(idJabatan !== null) {
        let result = await this.edit(idJabatan);
        if(result !== 'close' && result !== null) {
          this.messageService.set('jataban:afterUpdate', result);
        }
      } else {
        this.aksi = 'insert';
        let result = await this.insert();
        if(result !== 'close' && result !== null) {
          this.messageService.set('jataban:afterInsert', result);
        }
      }

      this.router.navigate([idJabatan !== null ? '../../' : '../'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge'
      });
    } catch (e: any) {
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['Ok']
      });
    }
    
  }  

}

