import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { UnitService } from 'src/app/services/unit.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WuiService, MessageService } from '@wajek/wui';
import { takeUntil, debounceTime } from 'rxjs';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrl: './unit.component.scss'
})
export class UnitComponent implements OnInit {

  @ViewChild('searchInput', { static: false }) searchInput?: ElementRef;

  lastPage = false;
  isLoading = false;

  dataUnit: Array<any> = [];
  selectedIndex = -1;

  count = 0;
  limit = 30;

  q = '';
  search: Subject<any> = new Subject();
  searchMode = false;

  page: any = 1;

  get offset() {
    return (this.page - 1) * this.limit;
  }

  get pageCount() {
    return Math.ceil(this.count / this.limit);

  }
  
  private unsub: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private unitService: UnitService,
    private wuiService: WuiService,
    private messageService: MessageService
  ) { }

  toggleSearch() {
    this.searchMode = !this.searchMode;
    
    setTimeout(() => {
      this.searchInput?.nativeElement.focus();
    }, 0);

    if (!this.searchMode) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          page: 1,
          search: null
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  first() {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: 1
      },
      queryParamsHandling: 'merge'
    });
  }

  prev() {
    if (this.page > 1) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          page: this.page - 1
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  next() {
    if (this.page < this.pageCount) {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          page: this.page + 1
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  last() {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: this.pageCount
      },
      queryParamsHandling: 'merge'
    });
  }
  async hapus() {
    let confirm = await this.wuiService.dialog({
      title: 'Konfirmasi',
      message: 'Anda yakin untuk menghapus unit terpilih ?',
      buttons: ['Batal', 'Hapus']
    });
    if (confirm == 1) {
      try {
        this.wuiService.openLoading();
        await this.unitService.delete(this.dataUnit[this.selectedIndex]?.idUnit);
        this.wuiService.snackbar({
          label: 'Unit berhasil dihapus'
        });
        this.wuiService.closeLoading();
        this.dataUnit.splice(this.selectedIndex, 1);
      } catch (e: any) {
        this.wuiService.closeLoading();
        this.wuiService.dialog({
          title: 'Error',
          message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
          buttons: ['OK']
        });
      }
    }
  }

  async refresh() {
    try {
      this.dataUnit = [];
      this.isLoading = true;
      let params: any = {
        offset: this.offset,
        limit: this.limit
      };
      if (this.q != null) { params.search = this.q; }
      let res: any = await this.unitService.result(params);
      this.dataUnit = res.data;
      this.count = res.count;
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.mgs ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }

  ngOnInit(): void {
    this.messageService.get('unit:afterUpdate').pipe(takeUntil(this.unsub)).subscribe(res => {
      this.wuiService.snackbar({
        label: 'Unit berhasil diupdate'
      });
      this.refresh();
    });

    this.messageService.get('unit:afterInsert').pipe(takeUntil(this.unsub)).subscribe(res => {
      this.wuiService.snackbar({
        label: 'Unit berhasil ditambahkan'
      });
      this.refresh();
    });

    this.search.pipe(takeUntil(this.unsub), debounceTime(500)).subscribe(e => {
      if(this.searchMode) {
        this.router.navigate(['./'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            page: 1,
            search: e.target.value,
          },
          queryParamsHandling: 'merge'
        });
      } else { 
        this.q = '';
        return; 
      }
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.unsub)).subscribe(queryParams => {
      this.page = queryParams['page'] != null ? parseInt(queryParams['page']) : 1;
      this.q = queryParams['search'] ?? null;
      this.searchMode = this.q != null;
      this.refresh();
    });
  }

  ngOnDestroy() {
    this.unsub.next(null);
  }
}
