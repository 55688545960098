<wui-page>
    <wui-app-bar>
        <div class="wui-app-bar-leading">
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="back()">
                <wui-icon icon="arrow-left"></wui-icon>
            </button>
        </div>
        <div class="wui-app-title"></div>
        <div class="wui-app-bar-trailing"></div>
    </wui-app-bar>

    <div class="wui-page-inner">
        <div class="wui-page-container">
            <form [formGroup]="formLaporanTerkait" (submit)="submit()">
                <div class="mb-5 d-flex align-items-center">
                    <div class="flex-grow-1 font-28 font-weight-500">{{aksi == 'insert' ? 'Laporan Terkait Baru' : 'Edit Laporan Terkait'}}</div>
                    <button class="wui-button wui-button-primary px-4" type="submit">
                        <wui-icon icon="content-save"></wui-icon>{{aksi == 'insert' ? 'Simpan' : 'Update'}}
                    </button>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <wui-form-field class="wui-form-field-boxed">
                            <label for="jenisLaporan">Jenis Laporan</label>
                            <input type="text" name="jenisLaporan" id="jenisLaporan" readonly wuiInput [ngModel]="jenisLaporan" [ngModelOptions]="{standalone: true}">
                        </wui-form-field>
                    </div>
                    <div class="col-md-4">
                        <wui-form-field class="wui-form-field-boxed">
                            <label for="tanggal">Tanggal Laporan</label>
                            <input id="tanggal" type="datetime-local" wuiInput readonly [ngModel]="labelTglLaporan" [ngModelOptions]="{standalone: true}">
                        </wui-form-field>
                    </div>
                    <div class="col-md-4">
                        <wui-form-field class="wui-form-field-boxed">
                            <label for="status">Status Laporan</label>
                            <select name="status" id="status" wuiInput formControlName="status">
                                <option value="BELUM DIKERJAKAN">BELUM DIKERJAKAN</option>
                                <option value="SEDANG DIKERJAKAN">SEDANG DIKERJAKAN</option>
                                <option value="SELESAI">SELESAI</option>
                            </select>
                        </wui-form-field>
                    </div>
                </div>

                <wui-form-field class="wui-form-field-boxed">
                    <label for="uraian">Uraian Tugas / Keterangan</label>
                    <textarea name="uraian" id="uraian" formControlName="uraian" readonly rows="5" wuiInput></textarea>
                </wui-form-field>

                <wui-form-field *ngIf="formLaporanTerkait.controls['status'].value == 'SELESAI'" class="wui-form-field-boxed">
                    <label for="tindakLanjut">Tindak Lanjut Laporan</label>
                    <textarea name="tindakLanjut" id="tindakLanjut" formControlName="tindakLanjut" rows="5" wuiInput></textarea>
                </wui-form-field>
            </form>
        </div>
    </div>
</wui-page>

<router-outlet></router-outlet>