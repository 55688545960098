import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ModalComponent } from '@wajek/wui';
import { UnitCariComponent } from '../../unit/unit-cari/unit-cari.component';
import { UnitService } from 'src/app/services/unit.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-laporan-filter',
  templateUrl: './laporan-filter.component.html',
  styleUrl: './laporan-filter.component.scss'
})
export class LaporanFilterComponent {

  @ViewChild('modal', {static: true}) modal?: ModalComponent;
  @ViewChild('formCariUnit', {static: true}) formCariUnit?: UnitCariComponent; 

  private result: Subject<any> = new Subject();

  endPoint: any;
  penggunaAktif: any;

  unit: any;
  get labelUnit() {
    return this.unit?.nmUnit ?? 'Semua Unit';
  }

  jenis: any;
  get labelJenis() {
    return this.jenis ?? 'Semua Jenis';
  }

  status: any;
  get labelStatus() {
    return this.status ?? 'Semua Status';
  }

  formFilter = new UntypedFormGroup({
    awal: new UntypedFormControl(),
    akhir: new UntypedFormControl(),
    idUnit: new UntypedFormControl(),
    jenis: new UntypedFormControl(),
    status: new UntypedFormControl()
  }); 

  constructor(
    private datePipe: DatePipe,
    private unitService: UnitService,
    private authService: AuthService,
    private router: Router
  ) { }

  open(filter: any) {
    this.endPoint = this.router.url;
    return new Promise(async (resolve) => {
      this.formFilter.controls['awal'].setValue(filter?.awal != null ? this.datePipe.transform(filter?.awal, 'yyyy-MM-dd'): null);
      this.formFilter.controls['akhir'].setValue(filter?.akhir != null ? this.datePipe.transform(filter?.akhir, 'yyyy-MM-dd'): null);
      this.formFilter.controls['idUnit'].setValue(filter.idUnit ?? null);
      this.formFilter.controls['jenis'].setValue(filter.jenis ?? 'SEMUA JENIS');
      this.formFilter.controls['status'].setValue(filter.status ?? 'SEMUA STATUS');
      this.modal?.open();

      if(filter.idUnit != null) {
        this.unit = await this.unitService.row(filter.idUnit);
      } else {
        this.unit = null;
      }

      let sub = this.result.subscribe(res => {
        sub.unsubscribe();
        this.modal?.close();
        resolve(res);
      })
    })
  }

  async pilihUnit() {
    let unit = await this.formCariUnit?.open();
    if(unit != null && unit != 'close') {
      this.formFilter.controls['idUnit'].setValue(unit.idUnit);
      this.unit = unit;
    }
  }

  resetUnit() {
    this.unit = null;
    this.formFilter.controls['idUnit'].setValue(null);
  }

  close() {
    this.result.next('close');
  }

  reset() {
    this.unit = null;
    this.result.next({
      awal: null,
      akhir: null,
      idUnit: null,
      jenis: 'SEMUA JENIS',
      status: 'SEMUA STATUS'
    });
  }

  submit() {
    this.result.next({
      awal: this.formFilter.controls['awal'].value,
      akhir: this.formFilter.controls['akhir'].value,
      idUnit: this.formFilter.controls['idUnit'].value,
      jenis: this.formFilter.controls['jenis'].value,
      status: this.formFilter.controls['status'].value
    });
  }

  async ngOnInit() {
    this.authService.penggunaAktif.subscribe(penggunaAktif => {
      this.penggunaAktif = penggunaAktif;
    });  
  }

}
