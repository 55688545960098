<form [formGroup]="formKerjasama" (ngSubmit)="submit()">
    <wui-page>
        <wui-app-bar>
            <div class="wui-app-bar-leading">
                <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" type="button" (click)="back()">
                    <wui-icon icon="arrow-left"></wui-icon>
                </button>
            </div>
            <div class="wui-app-bar-title"></div>
            <div class="wui-app-bar-trailing"></div>
        </wui-app-bar>
    
        <div class="wui-page-inner">
            <div class="wui-page-container">
                <ul class="wui-breadcrumb">
                    <li>Home</li>
                    <li>Kerjasama</li>
                    <li>Kerjasama Baru</li>
                </ul>
                <div class="pb-5 font-32 font-weight-500">{{aksi == 'insert' ? 'Kerjasama Baru' : 'Edit Kerjasama'}}</div>
    
                
                <div class="pb-3">
                    <div class="row">
                        <div class="col-12">
                            <wui-form-field>
                                <label for="nmKerjasama">Nama Kerjasama</label>
                                <input id="nmKerjasama" type="text" wuiInput formControlName="nmKerjasama">
                            </wui-form-field>
                        </div>
                    </div>
        
                    <div class="row">
                        <div class="col-6">
                            <wui-form-field>
                                <label for="tglAwal">Tanggal Awal Kerjasama</label>
                                <input id="tglAwal" type="date" wuiInput formControlName="tglAwal">
                            </wui-form-field>
                        </div>
                        <div class="col-6">
                            <wui-form-field>
                                <label for="tglAkhir">Tanggal Akhir Kerjasama</label>
                                <input id="tglAkhir" type="date" wuiInput formControlName="tglAkhir">
                            </wui-form-field>
                        </div>
                    </div>
                </div>
    
                <div class="row pb-4">
                    <div class="col-12">
                        <div class="font-20 font-weight-500">Berkas Kerjasama</div>
                    </div>
                </div>
    
                <!-- <div class="row">
                    <div class="col-6">
                        <wui-form-field>
                            <label for="mou1">Jenis Mou 1</label>
                            <input id="mou1" type="text" wuiInput formControlName="mou1">
                        </wui-form-field>
                    </div>
                    <div class="col-6">
                        <wui-form-field>
                            <input type="file" name="file">
                        </wui-form-field>
                    </div>
                </div> -->
                
                <div class="row">
                    <div class="col-8">
                        <label class="btn btn-default p-0">
                            <!-- <input type="file" multiple (change)="selectFiles($event)" /> -->
                        </label>
                    </div>
    
                    <div class="col-4">
                        <!-- <button class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="uploadFiles()">
                            Upload
                        </button> -->
                    </div>
                </div>
                
                <!-- <div class="col-3">
                    <div class="btn btn-outline-secondary btn-file">
                        <label for="logo">Add Logo</label>
                        <input type="file" name="logo">
                        <p class="help-block">Max. 1MB</p>
                    </div>
                </div> -->
            </div>
        </div>
    </wui-page>
</form>