import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn: BehaviorSubject<any> = new BehaviorSubject(null);
  penggunaAktif: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private httpClient: HttpClient
  ) { }

  async init() {
    if(!localStorage.getItem('token')) {
      this.isLoggedIn.next(false);
      return;
    }

    try {
      let penggunaAktif: any = await this.info();
      this.penggunaAktif.next(penggunaAktif);
      this.isLoggedIn.next(true);
    } catch (e: any) {
      this.isLoggedIn.next(false);
      throw e;
    }
  }

  async info() {
    return await firstValueFrom(this.httpClient.get(this.apiUrl + 'auth', {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('token') || ''
      })
    }));
  }

  async signIn(kodeUser: any, password: any) {
    let res: any = await firstValueFrom(this.httpClient.post(this.apiUrl + 'auth', {
      kodeUser: kodeUser,
      password: password
    }));
    localStorage.setItem('token', res.token);
    let penggunaAktif: any = await this.info();

    this.penggunaAktif.next(penggunaAktif);
    this.isLoggedIn.next(true);
  }

  async updatePassword(data: any) {
    return await firstValueFrom(this.httpClient.post(this.apiUrl + 'auth/changePassword', data, {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('token') || ''
      })
    }));
  }

  signOut() {
    localStorage.removeItem('token');
    this.penggunaAktif.next(null);
    this.isLoggedIn.next(false);
  }

}
