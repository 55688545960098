<wui-page>
    <wui-app-bar>
        @if (searchMode == true) {
            <div class="wui-app-bar-leading">
                <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="toggleSearch()">
                    <wui-icon icon="arrow-left"></wui-icon>
                </button>
            </div>
            <div class="wui-app-bar-title">
                <input type="text" (keyup)="search.next($event)" placeholder="Kata kunci..." [(ngModel)]="q" #searchInput>
            </div>
        }

        @if (!searchMode) {
            <div class="wui-app-bar-leading">
                <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" wuiToggleDrawer>
                    <wui-icon icon="menu"></wui-icon>
                </button>
            </div>
            <div class="wui-app-bar-title"></div>
            <div class="wui-app-bar-trailing">
                <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="filter()">
                    <wui-icon icon="{{isFiltered ? 'filter' : 'filter-outline'}}"></wui-icon>
                </button>
                <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="toggleSearch()">
                    <wui-icon icon="magnify"></wui-icon>
                </button>
                <button class="wui-button wui-button-large wui-button-icon wui-button-smooth"  (click)="refresh()">
                    <wui-icon icon="refresh"></wui-icon>
                </button>
            </div>
        }
    </wui-app-bar>

    <div class="wui-page-inner">
        <div class="wui-page-container">
            <div class="mb-5 d-flex align-items-center">
                <div class="flex-grow-1">
                    <div class="font-28 font-weight-500">Data Laporan</div>
                    <div class="d-flex align-items-center">
                        <div class="d-inline-flex align-items-center pe-4">
                            <div class="flex-grow-1 pe-2">
                                <span *ngIf="isLoading">Memuat...</span>
                                <span *ngIf="!isLoading">{{awal | date: 'dd MMM yyyy'}} &mdash; {{akhir | date: 'dd MMM yyyy'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button class="wui-button wui-button-primary" routerLink="baru" queryParamsHandling="merge">
                        <wui-icon icon="plus"></wui-icon> Baru
                    </button>
                </div>
            </div>

            <div class="table-overflow-container">
                <table class="table align-middle table-responsive-sm table-hover">
                    <thead>
                        <tr class="align-middle">
                            <th scope="col">#</th>
                            <th scope="col">Tanggal</th>
                            <th scope="col">Jenis</th>
                            <th *ngIf="penggunaAktif.akses != 'SPV' && penggunaAktif.akses != 'PELAKSANA'" scope="col">Unit</th>
                            <th scope="col">Uraian</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    @for (laporan of dataLaporan; track $index;) {
                        <tbody>
                            <tr (click)="selectedIndex = $index">
                                <th scope="row">{{offset + $index + 1}}</th>
                                <td>{{laporan?.tanggal.date | date: 'dd/MM/yyyy'}}</td>
                                <td><span class="status {{laporan.jenisLaporan == 'TANTANGAN' ? 'text-bg-danger' : (laporan.jenisLaporan == 'HUMAN ERROR' ? 'text-bg-warning' : 'text-bg-info')}}">{{laporan?.jenisLaporan}}</span></td>
                                <td *ngIf="penggunaAktif.akses != 'SPV' && penggunaAktif.akses != 'PELAKSANA'" class="text-wrap">{{laporan?.nmUnit}}</td>
                                <td><p class="long-text-container m-0">{{laporan.uraian ?? '-'}}</p></td>
                                <td>
                                    @if (laporan?.jenisLaporan != 'INFO') {
                                        <span *ngIf="laporan.uraian != null" class="status {{laporan.statusGenerated == 'SELESAI' ? 'text-bg-success' : (laporan.statusGenerated == 'SEDANG DIKERJAKAN' ? 'text-bg-warning' : 'text-bg-danger')}}">{{laporan?.statusGenerated}}</span>
                                    }
                                </td>
                                <td>
                                    <button class="wui-button wui-button-icon wui-button-smooth" [wuiMenu]="rowMenu">
                                        <wui-icon icon="dots-vertical"></wui-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    }
                </table>
                
                @if (isLoading === true) {
                    <div class="p-5 text-center mx-auto">
                        <wui-loading mode="circular"></wui-loading>
                    </div>
                }
            </div>

            @if (!isLoading) {
                <wui-list-tile class="p-0 wui-list-tile-64 wui-list-tile-no-hover" *ngIf="dataLaporan.length == 0">
                    <div class="wui-list-tile-content fst-italic">Tidak ada data</div>
                </wui-list-tile>
                <wui-list-tile class="wui-list-tile-no-hover wui-list-tile-64" *ngIf="dataLaporan.length > 0">
                    <div class="wui-list-tile-content">
                        <div class="d-inline-flex align-items-center">
                            <button class="wui-button wui-button-icon wui-button-smooth" (click)="first()" [disabled]="count == 0 || page == 1">
                                <wui-icon icon="page-first"></wui-icon>
                            </button>
                            <button class="wui-button wui-button-icon wui-button-smooth" (click)="prev()" [disabled]="count == 0 || page == 1">
                                <wui-icon icon="chevron-left"></wui-icon>
                            </button>
                            <div class="flex-grow-1 px-4">
                                <span *ngIf="isLoading">Memuat...</span>
                                <span *ngIf="!isLoading">{{offset + 1}} - {{count < (offset + limit) ? count : (offset + limit)}} dari {{count | number: '1.0-2' }} baris</span>
                            </div>
                            <button class="wui-button wui-button-icon wui-button-smooth" (click)="next()" [disabled]="count == 0 || page == pageCount">
                                <wui-icon icon="chevron-right"></wui-icon>
                            </button>
                            <button class="wui-button wui-button-icon wui-button-smooth"  (click)="last()" [disabled]="count == 0 || page == pageCount">
                                <wui-icon icon="page-last"></wui-icon>
                            </button>
                        </div>
                    </div>
                </wui-list-tile>
            }
        </div>
    </div>
</wui-page>

<router-outlet></router-outlet>

<wui-menu class="compact" #rowMenu>
    <wui-menu-item icon="information" routerLink="{{this.dataLaporan[selectedIndex]?.idLaporan}}"queryParamsHandling="merge">Detail Laporan</wui-menu-item>
    <wui-menu-item *ngIf="penggunaAktif.akses != 'PELAKSANA'" icon="lead-pencil" routerLink="edit/{{this.dataLaporan[selectedIndex]?.idLaporan}}" queryParamsHandling="merge">Edit</wui-menu-item>
    <wui-menu-item *ngIf="penggunaAktif.akses != 'PELAKSANA'" icon="trash-can" (click)="hapus()">Hapus</wui-menu-item>
</wui-menu>

<app-laporan-filter #formLaporanFilter></app-laporan-filter>