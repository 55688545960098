<form [formGroup]="formUnit" (ngSubmit)="submit()">
    <wui-modal #modal [width]="450">
        <div class="wui-modal-header">
            <div class="d-flex justify-content-between align-items-center">
                <div>{{aksi == 'insert' ? 'Unit Baru' : 'Edit Unit'}}</div>
                <button class="wui-button wui-button-smooth wui-button-icon" type="button" (click)="close()">
                    <wui-icon icon="close"></wui-icon>
                </button>
            </div>
        </div>

        <div class="wui-modal-content pt-2">
            <wui-form-field class="wui-form-field-boxed">
                <label for="nmUnit">Nama Unit</label>
                <input id="nmUnit" type="text" wuiInput formControlName="nmUnit" #inputFocus>
            </wui-form-field>

            <wui-form-field class="wui-form-field-boxed">
                <label for="isAktif">Status Unit</label>
                <select name="isAktif" id="isAktifUnit" wuiInput formControlName="isAktif">
                    <option value="1">Aktif</option>
                    <option value="0">Tidak Aktif</option>
                </select>
            </wui-form-field>

            <div class="wui-toggle d-flex align-items-center">
                <input type="checkbox" id="isInduk" name="isInduk" formControlName="isInduk"/>
                <div class="wui-toggle-mark"></div>
                <label class="wui-toggle-label" for="isInduk">{{isIndukMode}}</label>
            </div>

            @if (this.formUnit.controls['isInduk'].value != 1) {
                <wui-form-field class="wui-form-field-boxed">
                    <label for="indukUnit">Pilih Unit Induk</label>
                    <input id="indukUnit" type="text" wuiInput readonly [ngModel]="labelUnit" [ngModelOptions]="{standalone: true}">
                    <div class="wui-form-field-suffix">
                        <button class="wui-button wui-button-icon wui-button-smooth" type="button" (click)="pilihIndukUnit()">
                            <wui-icon icon="chevron-down"></wui-icon>
                        </button>
                    </div>
                </wui-form-field>
            }
        </div>

        <div class="wui-modal-footer">
            <div class="d-flex justify-content-between align-items-center">
                <button class="wui-button" type="button" (click)="close()">Batal</button>
                <button class="wui-button wui-button-primary" type="submit">Simpan</button>
            </div>
        </div>
    </wui-modal>
</form>

<app-unit-cari #formCariUnit></app-unit-cari>