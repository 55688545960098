import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WuiService } from '@wajek/wui';
import { LaporanUnitService } from 'src/app/services/laporan-unit.service';
import { LaporanService } from 'src/app/services/laporan.service';

@Component({
  selector: 'app-laporan-terkait-detail',
  templateUrl: './laporan-terkait-detail.component.html',
  styleUrl: './laporan-terkait-detail.component.scss'
})
export class LaporanTerkaitDetailComponent implements OnInit{

  isLoading = false;
  idLaporanUnit: any;
  laporanUnit: any;
  laporan: any;

  constructor(
    private laporanUnitService: LaporanUnitService,
    private wuiService: WuiService,
    private activatedRoute: ActivatedRoute,
    private laporanService: LaporanService,
    private router: Router
  ) { }

  back() {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge'
    });
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.laporanUnit = await this.laporanUnitService.row(this.idLaporanUnit);
      this.laporan = await this.laporanService.row(this.laporanUnit?.idLaporan);
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.wuiService.dialog({
        title: "Error",
        message: e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['Ok']
      });
    }
  }

  ngOnInit(): void {
    this.idLaporanUnit = this.activatedRoute.snapshot.params['idLaporanUnit'];
    this.refresh();
  }

}
