<wui-page>
    <wui-app-bar>
        @if (searchMode) {
        <div class="wui-app-bar-leading">
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="toggleSearch()">
                <wui-icon icon="arrow-left"></wui-icon>
            </button>
        </div>
        <div class="wui-app-bar-title">
            <input type="text" (keyup)="search.next($event)" placeholder="Kata kunci..." [(ngModel)]="q" #searchInput>
        </div>
        }

        @if (!searchMode) {
        <div class="wui-app-bar-leading">
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" wuiToggleDrawer>
                <wui-icon icon="menu"></wui-icon>
            </button>
        </div>
        <div class="wui-app-bar-title"></div>
        <div class="wui-app-bar-trailing">
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="toggleSearch()">
                <wui-icon icon="magnify"></wui-icon>
            </button>
            <button class="wui-button wui-button-large wui-button-icon wui-button-smooth" (click)="refresh()">
                <wui-icon icon="refresh"></wui-icon>
            </button>
        </div>
        }
    </wui-app-bar>

    <div class="wui-page-inner">
        <div class="wui-page-container">
            <div class="mb-5 d-flex align-items-center">
                <div class="flex-grow-1 font-28 font-weight-500">Data Kerjasama</div>
                <div>
                    <button class="wui-button wui-button-primary" routerLink="baru" queryParamsHandling="merge">
                        <wui-icon icon="plus"></wui-icon> Baru
                    </button>
                </div>
            </div>

            <wui-list class="wui-list-bordered wui-list-hovered">
                <wui-list-tile class="wui-list-tile-56 wui-list-tile-header">
                    <div class="wui-list-tile-content">
                        <div class="row">
                            <div class="col-4">Nama Kerjasama</div>
                            <div class="col-4">Awal Berlaku</div>
                            <div class="col-4">Akhir Berlaku</div>
                        </div>
                    </div>
                    <div class="wui-list-tile-trailing"></div>
                </wui-list-tile>

                @for (kerjasama of dataKerjasama; track $index;) {
                <wui-list-tile class="wui-list-tile-56" (click)="selectedIndex = $index">
                    <div class="wui-list-tile-content">
                        <div class="row align-items-center">
                            <div class="col-4 text-nowrap">
                                <div class="d-flex align-items-center">
                                    <div class="wui-list-tile-leading">
                                        <wui-icon icon="handshake"></wui-icon>
                                    </div>
                                    <div class="flex-grow-1">{{kerjasama.nmKerjasama}}</div>
                                </div>
                            </div>
                            <div class="col-4">{{kerjasama.tglAwal.date | date : "dd MMM yyyy"}}</div>
                            <div class="col-4">{{kerjasama.tglAkhir.date | date : "dd MMM yyyy"}}</div>
                        </div>
                    </div>
                    <div class="wui-list-tile-trailing">
                        <button class="wui-button wui-button-icon wui-button-smooth" [wuiMenu]="rowMenu">
                            <wui-icon icon="dots-vertical"></wui-icon>
                        </button>
                    </div>
                </wui-list-tile>
                }

                @if (isLoading === true) {
                <div class="loading p-5">
                    <wui-loading mode="circular"></wui-loading>
                </div>
                }

                @if (!isLoading) {
                    @if (dataKerjasama.length == 0) {
                    <wui-list-tile class="wui-list-tile-64 wui-list-tile-no-hover">
                        <div class="wui-list-tile-content fst-italic">Tidak ada data</div>
                    </wui-list-tile>
                    }

                    @if (dataKerjasama.length > 0) {
                    <div class="wui-list-tile-no-hover wui-list-tile-64">
                        <div class="wui-list-tile-content">
                            <div class="d-inline-flex align-items-center">
                                <button class="wui-button wui-button-icon wui-button-smooth" (click)="first()" [disabled]="count == 0 || page == 1">
                                    <wui-icon icon="page-first"></wui-icon>
                                </button>
                                <button class="wui-button wui-button-icon wui-button-smooth" (click)="prev()" [disabled]="count == 0 || page == 1">
                                    <wui-icon icon="chevron-left"></wui-icon>
                                </button>
                                <div class="flex-grow-1 px-4">
                                    <span *ngIf="isLoading">Memuat...</span>
                                    <span *ngIf="!isLoading">{{offset + 1}} - {{count < (offset + limit) ? count : (offset + limit)}} dari {{count | number: '1.0-2'}} baris</span>
                                </div>
                                <button class="wui-button wui-button-icon wui-button-smooth" (click)="next()" [disabled]="count == 0 || page == pageCount">
                                    <wui-icon icon="chevron-right"></wui-icon>
                                </button>
                                <button class="wui-button wui-button-icon wui-button-smooth" (click)="last()" [disabled]="count == 0 || page == pageCount">
                                    <wui-icon icon="page-last"></wui-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                }
            </wui-list>
        </div>
    </div>
</wui-page>

<router-outlet></router-outlet>

<wui-menu class="compact" #rowMenu>
    <wui-menu-item icon="lead-pencil" routerLink="edit/{{this.dataKerjasama[selectedIndex]?.idKerjasama}}"
        queryParamsHandling="merge">Edit</wui-menu-item>
    <wui-menu-item icon="trash-can" (click)="hapus()">Hapus</wui-menu-item>
</wui-menu>