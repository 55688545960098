import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private router: Router
  ) { }

  async get(url: string, options: any = null): Promise<any> {
    try {
      let opt = Object.assign(options ?? {}, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem('token') || ''
        })
      });
      let res: any = await firstValueFrom(this.httpClient.get<any>(url, opt));
      if(res?.refreshToken) {
        localStorage.setItem('token', res.refreshToken);
      }
      return res;  
    } catch (e: any) {
      if(e.error?.code == 'auth/invalid-token') {
        this.authService.signOut();
        this.router.navigate(['/login']);
      }
      throw e;
    }
  }

  async post(url: string, body: any, options: any = null): Promise<any> {
    try {
      let opt = Object.assign(options ?? {}, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem('token') || ''
        })
      });
      let res: any = await firstValueFrom(this.httpClient.post(url, body, opt));
      if(res?.refreshToken) {
        localStorage.setItem('token', res.refreshToken);
      }
      return res;
    } catch (e: any) {
      if(e.error?.code == 'auth/invalid-token') {
        this.authService.signOut();
        this.router.navigate(['/login']);
      }
      throw e;
    }
  }

  async patch(url: string, body: any, options: any = null): Promise<any> {
    try {
      let opt = Object.assign(options ?? {}, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem('token') || ''
        })
      });
      let res: any = await firstValueFrom(this.httpClient.patch<any>(url, body, opt));
      if(res?.refreshToken) {
        localStorage.setItem('token', res.refreshToken);
      } 
      return res;
    } catch (e: any) {
      if(e.error?.code == 'auth/invalid-token') {
        this.authService.signOut();
        this.router.navigate(['/login']);
      }
      throw e;
    }
  }

  async delete(url: string, options: any = null): Promise<any> {
    try {
      let opt = Object.assign(options ?? {}, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem('token') || ''
        })
      });
      let res: any = await firstValueFrom(this.httpClient.delete<any>(url, opt));
      if(res?.refreshToken) {
        localStorage.setItem('token', res.refreshToken);
      }
      return res;
    } catch (e: any) {
      if(e.error?.code == 'auth/invalid-token') {
        this.authService.signOut();
        this.router.navigate(['/login']);
      }
      throw e;
    }
  }

}
