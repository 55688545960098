import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class LaporanService {

  constructor(
    private httpService: HttpService,
    @Inject('apiUrl') private apiUrl: string
  ) { }

  async result(params: any = null) {
    return await this.httpService.get(this.apiUrl + 'laporan', {
      params: params
    })
  }

  async row(idLaporan: any) {
    return await this.httpService.get(this.apiUrl + 'laporan/' + idLaporan);
  }

  async insert(data: any) {
    return await this.httpService.post(this.apiUrl + 'laporan', data);
  }

  async update(idLaporan: any, data: any) {
    return await this.httpService.patch(this.apiUrl + 'laporan/' + idLaporan, data);
  }

  async delete(idLaporan: any) {
    return await this.httpService.delete(this.apiUrl + 'laporan/' + idLaporan);
  }

}
