import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class LaporanUnitService {

  constructor(
    private httpService: HttpService,
    @Inject('apiUrl') private apiUrl: string
  ) { }

  // async result(idLaporan: any, params: any = null) {
  //   return await this.httpService.get(this.apiUrl + 'laporan/' + idLaporan + '/unit', {
  //     params: params
  //   })
  // }

  // async row(idLaporan: any, idLaporanUnit: any) {
  //   return await this.httpService.get(this.apiUrl + 'laporan/' + idLaporan + '/unit/' + idLaporanUnit);
  // }

  // async insert(idLaporan: any, data: any) {
  //   return await this.httpService.post(this.apiUrl + 'laporan' + idLaporan + '/unit', data);
  // }

  // async update(idLaporan:any, idLaporanUnit: any, data: any) {
  //   return await this.httpService.patch(this.apiUrl + 'laporan/' + idLaporan + '/unit/' + idLaporanUnit, data);
  // }

  // async delete(idLaporan: any, idLaporanUnit: any) {
  //   return await this.httpService.delete(this.apiUrl + 'laporan/' + idLaporan + '/unit/' + idLaporanUnit);
  // }

  async result(params: any = null) {
    return await this.httpService.get(this.apiUrl + 'laporanUnit/', {
      params: params
    })
  }

  async row(idLaporanUnit: any) {
    return await this.httpService.get(this.apiUrl + 'laporanUnit/' + idLaporanUnit);
  }

  async insert(data: any) {
    return await this.httpService.post(this.apiUrl + 'laporanUnit', data);
  }

  async update(idLaporanUnit: any, data: any) {
    return await this.httpService.patch(this.apiUrl + 'laporanUnit/' + idLaporanUnit, data);
  }

  async delete(idLaporanUnit: any) {
    return await this.httpService.delete(this.apiUrl + 'laporanUnit/' + idLaporanUnit);
  }
  
}
