<form [formGroup]="formItem" (ngSubmit)="submit()">
    <wui-modal #modal [width]="650">
        <div class="wui-modal-header">
            <div class="d-flex justify-content-between align-items-center">
                <div>Detail Laporan Unit</div>
                <button class="wui-button wui-button-smooth wui-button-icon" type="button" (click)="close()">
                    <wui-icon icon="close"></wui-icon>
                </button>
            </div>
        </div>
    
        <div class="wui-modal-content">
            <div class="mb-2">
                <wui-form-field class="wui-form-field-boxed">
                    <label for="idUnit">Pilih Unit Terkait</label>
                    <input id="idUnit" type="text" readonly wuiInput [ngModel]="labelUnit" [ngModelOptions]="{standalone: true}">
                    <div class="wui-form-field-suffix">
                        <button class="wui-button wui-button-smooth wui-button-icon" type="button" (click)="pilihUnit()">
                            <wui-icon icon="menu-down"></wui-icon>
                        </button>
                    </div>
                </wui-form-field>
            </div>

            <wui-form-field class="wui-form-field-boxed">
                <label for="uraian">Uraian / Keterangan (opsional)</label>
                <textarea name="uraian" id="uraian" formControlName="uraian" rows="5" wuiInput></textarea>
            </wui-form-field>

            @if(aksi == 'edit') {
                <wui-form-field class="wui-form-field-boxed">
                    <label for="status">Status</label>
                    <select name="status" id="status" wuiInput formControlName="status">
                        <option value="BELUM DIKERJAKAN">BELUM DIKERJAKAN</option>
                        <option value="SEDANG DIKERJAKAN">SEDANG DIKERJAKAN</option>
                        <option value="SELESAI">SELESAI</option>
                    </select>
                </wui-form-field>
            }

            @if(formItem.controls['status'].value == 'SELESAI') {
                <wui-form-field class="wui-form-field-boxed">
                    <label for="tindakLanjut">Tindak Lanjut</label>
                    <textarea name="tindakLanjut" id="tindakLanjut" formControlName="tindakLanjut" rows="5" wuiInput></textarea>
                </wui-form-field>
            }
        </div>
    
        <div class="wui-modal-footer">
            <div class="d-flex justify-content-between align-items-center">
                <button class="wui-button wui-button-secondary" type="button" (click)="close()">Batal</button>
                <button class="wui-button wui-button-primary" type="submit">
                    <wui-icon icon="content-save"></wui-icon>
                    Simpan
                </button>
            </div>
        </div>
    </wui-modal>
</form>

<app-unit-cari #formCariUnit></app-unit-cari>