<wui-page>
    <div class="wui-page-inner d-flex align-items-center justify-content-center">
        <div class="login-box text-left">
            <form [formGroup]="formLogin" (ngSubmit)="submit()">
                <div class="mb-5">
                    <div class="font-28 font-weight-500 mb-2">Sipat Yasmin</div>
                    <div>Masukkan Username dan Password anda untuk memulai aplikasi</div>
                </div>

                <wui-form-field class="wui-form-field-boxed">
                    <label for="kodeUser">Username</label>
                    <input id="kodeUser" type="text" wuiInput formControlName="kodeUser">
                    <div class="wui-form-field-suffix">
                        <wui-icon icon="account-box"></wui-icon>
                    </div>
                </wui-form-field>

                <wui-form-field class="wui-form-field-boxed">
                    <label for="password">Password</label>
                    <input id="password" [type]="showPassword ? 'text' : 'password'" (input)="onInput($event)" wuiInput formControlName="password">
                    <div class="wui-form-field-suffix d-flex">
                        <div class="wui-form-field-suffix" (click)="toggleShowPassword()">
                            @if (showPassword) {
                                <wui-icon *ngIf="isTyping" icon="eye-outline"></wui-icon>
                            } @else {
                                <wui-icon *ngIf="isTyping" icon="eye-off-outline"></wui-icon>
                            }
                        </div>
                        <wui-icon icon="lock"></wui-icon>
                    </div>
                </wui-form-field>

                <div class="pt-3 pb 4">
                    <button class="wui-button wui-button-primary">Masuk</button>
                </div>
            </form>
        </div>
    </div>
</wui-page>