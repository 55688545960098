import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService, WuiService } from '@wajek/wui';
import { LaporanService } from 'src/app/services/laporan.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UnitCariComponent } from '../../unit/unit-cari/unit-cari.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-laporan-form',
  templateUrl: './laporan-form.component.html',
  styleUrl: './laporan-form.component.scss'
})
export class LaporanFormComponent implements OnInit {

  @ViewChild('formCariUnit', { static: true }) formCariUnit?: UnitCariComponent;

  penggunaAktif: any;
  aksi = 'insert';
  idLaporan: any;

  unit: any = null;
  get labelUnit() {
    return this.unit?.nmUnit ?? null;
  }

  status: any;
  get labelStatus() {
    return this.status ?? null;
  }
 
  formLaporan = new UntypedFormGroup({
    jenisLaporan: new UntypedFormControl(null, Validators.required),
    uraian: new UntypedFormControl(null, Validators.required),
    tanggal: new UntypedFormControl(null, Validators.required),
    idUnit: new UntypedFormControl(null),
  });

  constructor(
    private wuiService: WuiService,
    private laporanService: LaporanService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private authService: AuthService
  ) { }

  getColSizeInput() {
    let colSize = 12;
    if(this.penggunaAktif.akses != 'PELAKSANA' && this.penggunaAktif.akses != 'SPV') {
      if(this.aksi == 'edit') {
        colSize = 4;
      } else {
        colSize = 6;
      }
    } else {
      if(this.aksi == 'edit') {
        colSize = 6;
      }
    }
    return 'col-md-' + colSize.toString();
  }

  async pilihUnit() {
    let unit: any = await this.formCariUnit?.open();
    if (unit != 'close' && unit != null) {
      this.formLaporan.controls['idUnit'].setValue(unit.idUnit);
      this.unit = unit;
    }
  }

  back(idLaporan: any = null) {
    this.router.navigate([(this.aksi == 'insert' ? '../' : '../../') + (idLaporan != null ? idLaporan : '')], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge'
    });
  }

  async submit() {
    if(this.formLaporan.invalid) {
      this.wuiService.dialog({
        title: 'Error',
        message: 'Periksa kembali isian anda',
        buttons: ['OK']
      });
      return;
    }  

    try {
      this.wuiService.openLoading();
      let data: any = this.formLaporan.value;
      if(this.aksi == 'insert') {
        let res: any = await this.laporanService.insert(data);
        data.idLaporan = res.idLaporan;
        this.messageService.set('laporan:afterInsert', data);
      } else {
        await this.laporanService.update(this.idLaporan, data);
        data.idLaporan = this.idLaporan;
        this.messageService.set('laporan:afterUpdate', data);
      }
      this.wuiService.closeLoading();
      this.back(data.idLaporan);
    } catch (e: any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }

  async ngOnInit() {
    this.authService.penggunaAktif.subscribe(penggunaAktif => {
      this.penggunaAktif = penggunaAktif;
    });

    this.idLaporan = this.activatedRoute.snapshot.params['idLaporan'];
    try {
      this.wuiService.openLoading();
      if(this.idLaporan) {
        this.aksi = 'edit';
        let res = await this.laporanService.row(this.idLaporan);
        this.formLaporan.controls['tanggal'].setValue(res.tanggal.date);
        this.formLaporan.controls['idUnit'].setValue(res.idUnit);
        this.formLaporan.controls['jenisLaporan'].setValue(res.jenisLaporan);
        this.formLaporan.controls['uraian'].setValue(res.uraian);
        this.status = res.statusGenerated;
        this.unit = {
          idUnit: res.idUnit,
          kodeUnit: res.kodeUnit,
          nmUnit: res.nmUnit
        }
      } else {
        this.aksi = 'insert';
        this.formLaporan.controls['tanggal'].setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm'));
      }
      this.wuiService.closeLoading();
    } catch (e:any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
    
  }

}
