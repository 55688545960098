import { Component, ViewChild, OnInit, ElementRef, Input, viewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent, WuiService, MessageService } from '@wajek/wui';
import { Subject } from 'rxjs';
import { UnitService } from 'src/app/services/unit.service';
import { UnitCariComponent } from '../unit-cari/unit-cari.component';

@Component({
  selector: 'app-unit-form',
  templateUrl: './unit-form.component.html',
  styleUrl: './unit-form.component.scss'
})
export class UnitFormComponent implements OnInit {

  @ViewChild('formCariUnit', { static: true }) formCariUnit?: UnitCariComponent;
  @ViewChild('modal', { static: true }) modal?: ModalComponent;
  @ViewChild('inputFocus', { static: false }) inputFocus?: ElementRef;

  aksi = 'insert';
  idUnit = 0;
  formUnit = new UntypedFormGroup({
    kodeUnit: new UntypedFormControl(null),
    nmUnit: new UntypedFormControl(null, Validators.required),
    isInduk: new UntypedFormControl(1),
    indukUnit: new UntypedFormControl(null),
    isAktif: new UntypedFormControl(1),
  })

  private $result: Subject<any> = new Subject();
  @Input('embed') embed = false;

  indukUnit: any;
  get labelUnit() {
    return this.indukUnit?.nmUnit ?? null;
  }

  get isIndukMode() {
    return this.formUnit.controls['isInduk'].value == 1 ? 'Unit Induk' : 'Unit Turunan';
  }

  constructor(
    private wuiService: WuiService,
    private unitService: UnitService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  async pilihIndukUnit() {
    let indukUnit: any = await this.formCariUnit?.open('INDUK');
    if(indukUnit != null && indukUnit != 'close') {
      this.indukUnit = indukUnit;
      this.formUnit.controls['indukUnit'].setValue(indukUnit.idUnit);
    }
  }

  close() {
    this.$result.next('close');
  }

  insert() {
    return new Promise((resolve) => {
      this.aksi = 'insert';
      this.formUnit.controls['kodeUnit'].setValue(null);
      this.formUnit.controls['nmUnit'].setValue(null);
      this.formUnit.controls['isInduk'].setValue(1);
      this.formUnit.controls['indukUnit'].setValue(null);
      this.formUnit.controls['isAktif'].setValue(1);

      this.modal?.open();
      setTimeout(() => {
        this.inputFocus?.nativeElement.focus();
      }, 0);

      let sub = this.$result.subscribe(result => {
        resolve(result);
        this.modal?.close();
        sub.unsubscribe();
      });
    });
  }

  edit(idUnit: any) {
    return new Promise(async (resolve) => {
      try {
        this.wuiService.openLoading();
        this.aksi = 'edit';
        this.idUnit = idUnit;

        let unit: any = await this.unitService.row(this.idUnit);
        this.formUnit.controls['kodeUnit'].setValue(unit?.kodeUnit);
        this.formUnit.controls['nmUnit'].setValue(unit?.nmUnit);
        this.formUnit.controls['indukUnit'].setValue(unit?.indukUnit);
        this.formUnit.controls['isAktif'].setValue(unit?.isAktif);
        this.indukUnit = unit?.nmUnitInduk;
        this.wuiService.closeLoading();

        this.modal?.open();
        setTimeout(() => {
          this.inputFocus?.nativeElement.focus();
        }, 100);

        let sub = this.$result.subscribe(result => {
          resolve(result);
          this.modal?.close();
          sub.unsubscribe();
        });
      } catch (e: any) {
        this.wuiService.closeLoading();
        this.wuiService.dialog({
          title: 'Error',
          message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
          buttons: ['OK']
        });
      }
    });
  }

  async submit() {
    if (this.formUnit.invalid) {
      this.wuiService.dialog({
        title: 'Error',
        message: 'Periksa kembali isian anda',
        buttons: ['OK']
      });
      return;
    };

    if (this.formUnit.controls['isInduk'].value != 1 && this.formUnit.controls['indukUnit'].value == null) {
      this.wuiService.dialog({
        title: 'Error',
        message: 'Pilih induk unit terlebih dahulu',
        buttons: ['OK']
      });
      return;
    }

    try {
      this.wuiService.openLoading();
      let data: any = {
        kodeUnit: this.formUnit.controls['kodeUnit'].value,
        nmUnit: this.formUnit.controls['nmUnit'].value,
        indukUnit: this.formUnit.controls['indukUnit'].value ?? 0,
        isAktif: this.formUnit.controls['isAktif'].value
      };

      if (this.aksi == 'insert') {
        let res: any = await this.unitService.insert(data);
        data.idUnit = res.idUnit;
        this.messageService.set('unit:afterInsert', data);
        this.wuiService.snackbar({
          label: 'Unit berhasil ditambahkan'
        });
      } else {
        await this.unitService.update(this.idUnit, data);
        data.idUnit = this.idUnit;
        this.messageService.set('unit:afterUpdate', data);
        this.wuiService.snackbar({
          label: 'Unit berhasil diupdate'
        });
      }
      this.wuiService.closeLoading();
      this.$result.next(data);
    } catch (e: any) {
      this.wuiService.closeLoading();
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }

  async ngOnInit() {
    if(this.embed) return;

    try {
      let idUnit = this.activatedRoute.snapshot.params['idUnit'] ?? null;
      if (idUnit !== null) {
        let result = await this.edit(idUnit);
        if (result !== 'close' && result !== null) {
          this.messageService.set('unit:afterUpdate', result);
        }
      } else {
        let result = await this.insert();
        if (result !== 'close' && result !== null) {
          this.messageService.set('unit:afterInsert', result);
        }
      }

      this.router.navigate([idUnit !== null ? '../../' : '../'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge'
      });
    } catch (e: any) {
      this.wuiService.dialog({
        title: 'Error',
        message: e.error?.msg ?? e.error?.message ?? e.message ?? 'Terjadi kesalahan, hubungi administrator',
        buttons: ['OK']
      });
    }
  }
}
