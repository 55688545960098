import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  constructor(
    private httpService: HttpService,
    @Inject('apiUrl') private apiUrl: string
  ) { }

  async sumLaporan(params: any = null) {
    return await this.httpService.get(this.apiUrl + 'summary/laporan', {
      params: params
    });
  }

}
