import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  constructor(
    private httpService: HttpService,
    @Inject('apiUrl') private apiUrl: string
  ) { }

  async result(params: any = null) {
    return await this.httpService.get(this.apiUrl + 'unit', {
      params: params
    })
  }

  async row(idUnit: any) {
    return await this.httpService.get(this.apiUrl + 'unit/' + idUnit);
  }

  async insert(data: any) {
    return await this.httpService.post(this.apiUrl + 'unit', data);
  }

  async update(idUnit: any, data: any) {
    return await this.httpService.patch(this.apiUrl + 'unit/' + idUnit, data);
  }

  async delete(idUnit: any) {
    return await this.httpService.delete(this.apiUrl + 'unit/' + idUnit);
  }
}
