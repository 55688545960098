<wui-modal #modal [width]="600">
    <div class="wui-modal-header">
        <div class="d-flex justify-content-between align-items-center">
            <div class="flex-grow-1">
                <input type="text" placeholder="Kata kunci..." [(ngModel)]="q" (keyup)="search.next($event)" #searchInput>
            </div>
            <button class="wui-button wui-button-smooth wui-button-icon" (click)="close()">
                <wui-icon icon="close"></wui-icon>
            </button>
        </div>
    </div>

    <div class="wui-modal-content wui-modal-content-no-padding">
        <wui-list class="wui-list-bordered wui-list-hovered">
            @for (jabatan of dataJabatan; track $index) {
                <wui-list-tile class="wui-list-tile-56">
                    <div class="wui-list-tile-content align-items-center">
                        <div class="d-flex align-items-center">
                            <div class="wui-list-tile-leading">
                                <button class="wui-button wui-button-icon wui-button-smooth">
                                    <wui-icon icon="account-tie"></wui-icon>
                                </button>
                            </div>
                            <div class="flex-grow-1 text-truncate text-nowrap">{{jabatan.nmJabatan}}</div>
                        </div>
                    </div>
                    <div class="wui-list-tile-trailing">
                        <button class="wui-button wui-button-icon wui-button-smooth" (click)="select(jabatan)">
                            <wui-icon icon="chevron-right"></wui-icon>
                        </button>
                    </div>
                </wui-list-tile>
            }

            @if (isLoading) {
                <div class="p-5 text-center">
                    <wui-loading mode="circular"></wui-loading>
                </div>
            }

            @if (!isLoading) {
                @if (dataJabatan.length == 0) {
                    <wui-list-tile class="wui-list-tile-56">
                        <div class="wui-list-tile-content fst-italic text-muted">Data Kosong</div>
                    </wui-list-tile>
                }

                <div class="wui-modal-footer pt-3 border-top wui-list-tile-footer d-flex align-items-center justify-content-between">
                    <div class="d-inline-flex align-items-center">
                        @if (dataJabatan.length > 0) {
                            <button class="wui-button wui-button-smooth wui-button-icon" (click)="first()" [disabled]="count == 0 || page == 1">
                                <wui-icon icon="page-first"></wui-icon>
                            </button>
                            <button class="wui-button wui-button-smooth wui-button-icon" (click)="prev()" [disabled]="count == 0 || page == 1">
                                <wui-icon icon="chevron-left"></wui-icon>
                            </button>
                            <div class="flex-grow-1 px-4 font-weight-500">
                                {{offset + 1}} - {{offset + limit >= count ? count : (offset + limit)}} dari {{count}} baris
                            </div>
                            <button class="wui-button wui-button-smooth wui-button-icon" (click)="next()" [disabled]="count == 0 || page == pageCount">
                                <wui-icon icon="chevron-right"></wui-icon>
                            </button>
                            <button class="wui-button wui-button-smooth wui-button-icon" (click)="last()" [disabled]="count == 0 || page == pageCount">
                                <wui-icon icon="page-last"></wui-icon>
                            </button>
                        }
                    </div>

                    <!-- <button class="wui-button wui-button-primary" type="button" (click)="tambahJabatan()">
                        <wui-icon icon="plus"></wui-icon>Baru
                    </button> -->
                </div>
            }
        </wui-list>
    </div>
</wui-modal>

<app-jabatan-form [embed]="true" #formJabatan></app-jabatan-form>