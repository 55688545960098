<wui-modal #modal [width]="500">
    <div class="wui-modal-header">
        <div class="d-flex justify-content-between align-items-center">
            <div class="flex-grow-1">
                <input type="text" placeholder="Kata Kunci..." [(ngModel)]="q" (keyup)="search.next($event)">
            </div>
            <button class="wui-button wui-button-smooth wui-button-icon" (click)="close()">
                <wui-icon icon="close"></wui-icon>
            </button>
        </div>
    </div>

    <div class="wui-modal-content wui-modal-content-no-padding">
        <div class="table-overflow-container me-2 ms-2">
            <table class="table align-middle table-responsive-sm table-hover mb-0">
                @for (unit of dataUnit; track $index;) {
                    <tbody>
                        <tr>
                            <td width="10%">
                                <button class="wui-button wui-button-icon wui-button-smooth">
                                    <wui-icon icon="office-building"></wui-icon>
                                </button>
                            </td>
                            <td width="90%"><p class="long-text-container m-0">{{unit?.nmUnit}}</p></td>
                            <td width="10%">
                                <button class="wui-button wui-button-icon wui-button-smooth" (click)="select(unit)">
                                    <wui-icon icon="chevron-right"></wui-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                }

                <div class="p-5 text-center" *ngIf="isLoading">
                    <wui-loading mode="circular"></wui-loading>
                </div>

                <wui-list-tile class="wui-list-tile-56" *ngIf="!isLoading && dataUnit.length == 0">
                    <div class="wui-list-tile-content fst-italic text-muted">Data Kosong</div>
                </wui-list-tile>
            </table>
        </div>

        <ng-container *ngIf="!isLoading">
            <div class="wui-modal-footer pt-2 wui-list-tile-footer d-flex align-items-center justify-content-between">
                <div class="d-inline-flex align-items-center">
                    @if (dataUnit.length > 0) {
                        <button class="wui-button wui-button-smooth wui-button-icon" (click)="first()" [disabled]="count == 0 || page == 1">
                            <wui-icon icon="page-first"></wui-icon>
                        </button>
                        <button class="wui-button wui-button-smooth wui-button-icon" (click)="prev()" [disabled]="count == 0 || page == 1">
                            <wui-icon icon="chevron-left"></wui-icon>
                        </button>
                        <div class="flex-grow-1 px-4 font-weight-500">
                            {{offset + 1}} - {{offset + limit >= count ? count : (offset + limit)}} dari {{count}} baris
                        </div>
                        <button class="wui-button wui-button-smooth wui-button-icon" (click)="next()" [disabled]="count == 0 || page == pageCount">
                            <wui-icon icon="chevron-right"></wui-icon>
                        </button>
                        <button class="wui-button wui-button-smooth wui-button-icon" (click)="last()" [disabled]="count == 0 || page == pageCount">
                            <wui-icon icon="page-last"></wui-icon>
                        </button>
                    }
                </div>

                <!-- <button class="wui-button wui-button-primary" type="button" (click)="tambahUnit()">
                    <wui-icon icon="plus"></wui-icon>Baru
                </button> -->
            </div>
        </ng-container>
    </div>
</wui-modal>